import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]
  static values = { rules: Object }

  connect() {
    this.conditionsMetByField = {}
    this.cleaningQueue = {}
    this.performOnAllFields()
  }

  performOnAllFields() {
    this.fieldTargets.forEach(fld => {
      this.perform(null, fld)
    })

    this.clearFields()
  }

  perform(e, fld = null) {
    const field = e ? e.target : fld
    const displayRulesKey = field.dataset.displayRulesKey
    let currentValue = field.value
    const container = field.closest("[data-display-rules-current-value]")
    const fieldType = container.dataset.displayRulesFieldType
    let forceHide = false

    if (fieldType == "Field::CheckBox") {
      currentValue = field.checked ? "checked" : "unchecked"
    } else if (["Field::GradeField", "Field::PublicSchoolDistrict", "Field::CountyField"].includes(fieldType)) {
      currentValue = field.options[field.selectedIndex].text
    } else if (fieldType == "Field::RadioField" || fieldType == "Field::BooleanField") {
      const radiosInContainer = container.querySelectorAll(`input[data-display-rules-key='${displayRulesKey}'`)
      let hasCheckedRadios = false
      let checkedRadio = null

      for(let radio of radiosInContainer) {
        if (radio.checked) {
          hasCheckedRadios = true
          checkedRadio = radio
          break
        }
      }

      if (!e && hasCheckedRadios && field != checkedRadio) {
        return
      } else if (!hasCheckedRadios) {
        currentValue = ""
        forceHide = true
      }
    }

    container.dataset.displayRulesCurrentValue = currentValue

    for(const [key, value] of Object.entries(this.rulesValue)) {
      for(const [k, v] of Object.entries(value)) {
        this.conditionsMetByField[key] ||= {}
        this.conditionsMetByField[key][displayRulesKey] ||= {}

        if (v["depends_on_field_id"] == displayRulesKey) {
          const fieldContainer = this.element.querySelector(`[data-display-rules-key='${key}']`)
          const fldType = fieldContainer.dataset.displayRulesFieldType

          if (forceHide) {
            fieldContainer.classList.add("hidden")
            break
          }

          const fld = fieldContainer.querySelector(`[data-app--form-field-display-rules-target='field']`)
          const anyOrAll = this.rulesValue[key][0]["condition"]

          if (v["form_field_type"] == "FormField::CheckBox") {
            currentValue = currentValue == "1" ? "checked" : "unchecked"
          }

          if (this.checkRuleMet(currentValue, v["operator"], v["value"])) {
            this.conditionsMetByField[key][displayRulesKey][k] = true
            const rulesMet = this.checkRulesMet(key)
            const allRulesMet = rulesMet["all"]
            const anyRulesMet = rulesMet["any"]

            if ((anyOrAll == "all" && allRulesMet) || anyOrAll == "any" && anyRulesMet) {
              fieldContainer.classList.remove("hidden")
              this.toggleSubFields(key, fieldContainer, "show")
              this.removeFromCleaningQueue(fld)
            } else {
              fieldContainer.classList.add("hidden")
              this.toggleSubFields(key, fieldContainer, "hide")
              this.addToCleaningQueue(fld, fldType)
            }
          } else {
            this.conditionsMetByField[key][displayRulesKey][k] = false
            const rulesMet = this.checkRulesMet(key)
            const allRulesMet = rulesMet["all"]
            const anyRulesMet = rulesMet["any"]
            if (anyOrAll == "any" && anyRulesMet) {
              this.removeFromCleaningQueue(fld)
            } else {
              fieldContainer.classList.add("hidden")
              this.toggleSubFields(key, fieldContainer, "hide")
              this.addToCleaningQueue(fld, fldType)
            }
          }
        }
      }
    }

    if (e) { this.clearFields() }
  }

  addToCleaningQueue(fld, fldType) {
    if (this.cleaningQueue[fld.dataset.displayRulesKey]) { return false }

    this.cleaningQueue[fld.dataset.displayRulesKey] = fldType
  }

  removeFromCleaningQueue(fld) {
    delete this.cleaningQueue[fld.dataset.displayRulesKey]
  }

  clearFields() {
    Object.keys(this.cleaningQueue).forEach((displayRulesKey) => {
      const fieldType = this.cleaningQueue[displayRulesKey]
      const fieldsToClear = document.querySelectorAll(`[data-display-rules-key='${displayRulesKey}']`)

      fieldsToClear.forEach(fieldToClear => this.clearField(fieldToClear, fieldType))
    })

    this.cleaningQueue = {}
  }

  clearField(fld, fldType) {
    if (["FormField::SectionHeader", "FormField::ContentSection", "FormField::TuitionField"].includes(fldType)) return

    if (["Field::RadioField", "Field::CheckBox", "Field::CheckBoxList", "Field::BooleanField", "Field::RaceSelectField"].includes(fldType)) {
      fld.checked = false
    } else if (fldType == "FormField::SignatureField") {
      fld.type == "text" ? fld.value = "" : fld.checked = false
    } else {
      fld.value = ""
    }
  }

  checkRuleMet(currentValue, operator, value) {
    const numericalComparisonOperators = ['>=', '>', '<=', '<']

    if(numericalComparisonOperators.includes(operator) && currentValue && value){
      return eval(`${currentValue} ${operator} ${value}`)
    }else{
      return eval(`'${currentValue}' ${operator} '${value}'`)
    }
  }

  checkRulesMet(inputKey) {
    let rulesMet = { all: true, any: false }

    for(const [key, value] of Object.entries(this.conditionsMetByField[inputKey])) {
      for(const [k, v] of Object.entries(this.conditionsMetByField[inputKey][key])) {
        if (!v) {
          rulesMet["all"] = false
        } else {
          rulesMet["any"] = true
        }
      }
    }

    return rulesMet
  }

  toggleSubFields(parentKey, fieldToShow, action) {
    for(const [key, value] of Object.entries(this.rulesValue)) {
      for(const [k, v] of Object.entries(value)) {
        if (v["depends_on_field_id"] == parentKey) {
          const subField = this.element.querySelector(`[data-display-rules-key='${key}']`)

          if (action == "hide") {
            subField.classList.add("hidden")
          } else if (action == "show") {
            if (fieldToShow.dataset.displayRulesCurrentValue == v["value"]) {
              subField.classList.remove("hidden")
            }
          }
        }
      }
    }
  }
}
