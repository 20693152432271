import { Controller } from "@hotwired/stimulus"
import Money from 'src/dinero-custom'

export default class extends Controller {
  static targets = ["input", "destinationContainer", "resetBtn"]
  static values = { initialInputs: Object, initialCalculations: Object, defaultDestination: String }

  connect() {
    this.hideContainer()
    this.storeInitialInputValues()
    this.storeInitialCalculationValues()
  }

  sendToDestination(e) {
    this.showContainer()

    let input = e.target

    let destination = document.querySelector(input.dataset.destination)
    let newInputValue = input.value.replaceAll(",", "")
    let colorClass = newInputValue >= 0 ? "green-text" : "red-text"

    if (newInputValue == "" || newInputValue == "0.00" ) {
      destination.innerHTML = this.defaultDestinationValue
    } else {
      destination.innerHTML = `<span class="${colorClass}">${new Money({ amount: newInputValue }).toText('$0,0')}</span>`
    }

    if (input.dataset.calculationDestination) {
      this.adjustCalculation(input)
    }
  }

  adjustCalculation(input) {
    let calcDestinations = document.querySelectorAll(input.dataset.calculationDestination)

    calcDestinations.forEach(destination => {
      let amt;

      if (input.value != "") {
        amt = this.initialCalculationsValue[destination.id] += parseInt(input.value.replace(/,/g, ''))
      } else {
        amt = this.initialCalculationsValue[destination.id]
      }

      destination.innerText = new Money({ amount: amt }).toText('$0,0')
    })
  }

  storeInitialInputValues() {
    let temp_obj = {}

    this.inputTargets.forEach(input => {
      temp_obj[input.id] = parseInt(input.value)
    })

    this.initialInputsValue = temp_obj
  }

  storeInitialCalculationValues() {
    let temp_obj = {}

    this.inputTargets.forEach(input => {
      let dest = input.dataset.calculationDestination
      let destElements = document.querySelectorAll(dest)

      if (destElements.length) {
        destElements.forEach(ele => {
          let initialValue = parseInt(ele.dataset["initialValue"]) || 0
          temp_obj[ele.id] = initialValue
        })
      }
    })

    this.initialCalculationsValue = temp_obj
  }

  showContainer() {
    if (this.hasDestinationContainerTarget) {
      this.destinationContainerTarget.classList.remove("hidden")
    }
  }

  hideContainer() {
    if (this.hasDestinationContainerTarget) {
      this.destinationContainerTarget.classList.add("hidden")
    }
  }
}
