import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    afterExpireUrl: String,
    refreshInterval: Number
  }

  static targets = ["modal"];

 connect() {
    if (this.hasRefreshIntervalValue) {
      this.startTimer()
    }
  }

  disconnect() {
    this.stopTimer()
    this.stopExpireTimer()
  }

  moreTime() {
    this.stopExpireTimer()
    this.stopTimer()

    if (this.hasRefreshIntervalValue) {
      this.startTimer()
    }

    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
    if (modalController.isShownValue == true) {
      modalController.close();
    }
  }

  showWarningModal() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );

    if (modalController.isShownValue == false) {
      modalController.open();
    }

    this.startExpireTimer()
  }

  expireSession() {
    if (this.afterExpireUrlValue) {
      window.location = this.afterExpireUrlValue;
    }
  }

  startTimer() {
    this.refreshTimer = setInterval(() => {
      this.showWarningModal()
    }, this.refreshIntervalValue)
  }

  startExpireTimer() {
    this.expireTimer = setInterval(() => {
      this.expireSession()
    }, 60000)
  }

  stopTimer() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopExpireTimer() {
    if (this.expireTimer) {
      clearInterval(this.expireTimer)
    }
  }
}
