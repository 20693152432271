import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["ruleContainer", "parentField", "operatorField", "valueField"]
  static values = { setters: Object }

  toggleOptions(e) {
    const selectedFieldId = e.target.value
    const ruleContainer = e.target.closest("[data--portal-form-field-display-rules-target='ruleContainer']")
    const operatorField = ruleContainer.querySelector("[data-portal-form-field-display-rules-target='operatorField']")
    const valueField = ruleContainer.querySelector("[data-portal-form-field-display-rules-target='valueField']")

    if (!selectedFieldId) {
      this.disableFields(operatorField, valueField)
    } else {
      this.enableFields(operatorField, valueField)
    }

    const setter = this.settersValue[selectedFieldId]

    if (setter) {
      operatorField.disabled = false
      this.replaceOperatorOptions(operatorField, setter["operators"])

      const possibleValues = setter["field_type"] == "FormField::CheckBox" ? ["checked"] : setter["possible_values"]
      this.replaceValueOptions(valueField, possibleValues)
    }
  }

  disableFields(operatorField = null, valueField = null) {
    if (operatorField) {
      operatorField.disabled = true
    }

    if (valueField) {
      valueField.disabled = true
    }
  }

  enableFields(operatorField = null, valueField = null) {
    if (operatorField) {
      operatorField.disabled = false
    }

    if (valueField) {
      valueField.disabled = false
    }
  }

  replaceOperatorOptions(selectField, opts) {
    Array.from(selectField).forEach((opt) => {
      selectField.removeChild(opt)
    })

    for (const [key, value] of Object.entries(opts)) {
      let newOption = document.createElement("option")
      newOption.appendChild(document.createTextNode(key))
      newOption.value = value
      selectField.appendChild(newOption)
    }
  }

  replaceValueOptions(valueField, opts) {
    Array.from(valueField).forEach((opt) => {
      valueField.removeChild(opt)
    })

    Array.from(opts).forEach((opt) => {
      let newOption = document.createElement("option")
      newOption.appendChild(document.createTextNode(opt))
      newOption.value = opt
      valueField.appendChild(newOption)
    })
  }
}
