import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["manager", "wrapper", "message"]
  static values = {}

  connect() {
    this.open()
  }

  open() {
    this.managerTarget.classList.remove("hidden")
    this.wrapperTarget.classList.add("toast-wrapper-enter")
    this.wrapperTarget.classList.add("toast-wrapper-enter-done")
    this.wrapperTarget.classList.remove("toast-wrapper-enter")

    setTimeout(() => {
      this.close()
    }, "5000")
  }

  close() {
    this.element.remove()
  }
}
