import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.expand()
  }

  expand() {
    if (this.element.scrollHeight === 0) {
      this.element.style.height = "56px"
    } else {
      this.element.style.height = "inherit"
      this.element.style.height = `${this.element.scrollHeight + 4}px`
    }
  }
}
