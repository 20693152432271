import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toField", "addToField", "addBccField", "hiddenRecipientField", "toInsertion", "bccInsertion", "listItem"]
  static values = { initialToEmails: Object }
  static classes = ["fieldError"]

  connect(){
    if (this.initialToEmailsValue && Object.keys(this.initialToEmailsValue).length !== 0) {
      for(const [email, alias] of Object.entries(this.initialToEmailsValue)) {
        this.insertRecipient(email, "to", alias)
      }
    }
  }

  handleKeydown(e) {
    const email = e.target.value
    const recipient_type = e.target.dataset.sendEmailRecipientType
    const alias = e.target.dataset.autocompleteAlias

    e.target.classList.remove(this.fieldErrorClass)

    if (e.keyCode === 13) {
      e.preventDefault()
      this.insertRecipient(email, recipient_type, alias)
    }
  }

  insertRecipient(email, recipient_type = "to", alias = null) {
    const addTarget = (recipient_type == "to" ? this.addToFieldTarget : this.addBccFieldTarget)
    const recipientAlreadyExists = this.recipientExists(email)

    if (!recipientAlreadyExists && this.validateEmail(email)) {
      this.addToFieldTarget.classList.remove(this.fieldErrorClass)

      const insertionTarget = recipient_type == "to" ? this.toInsertionTarget : this.bccInsertionTarget

      const deleteBtn = `<span class="send-email__remove-recipient-btn" data-send-email-remove-recipient="${email}" data-action="click->send-email#removeRecipient">&times;</span>`

      insertionTarget.insertAdjacentHTML("beforebegin", `
        <div class="send-email__to-email" title="${email}">
          ${alias ? alias : email}
          ${deleteBtn}
        </div>
      `)

      this.element.insertAdjacentHTML("beforeend", `<input multiple="multiple" value="${email}" type="hidden" name="${recipient_type}[]" data-send-email-target="hiddenRecipientField">`)
      addTarget.value = ""
    } else {
      addTarget.classList.add(this.fieldErrorClass)
    }

    this.closeAutocomplete()
  }

  removeRecipient(e) {
    e.preventDefault()

    const recipientToRemove = e.target.dataset.sendEmailRemoveRecipient
    e.target.parentElement.remove()

    for(const hiddenField of this.hiddenRecipientFieldTargets) {
      if (hiddenField.value == recipientToRemove) {
        hiddenField.remove()
      }
    }

    this.listItemTargets.forEach(listItem => {
      if (listItem.dataset.email == recipientToRemove) {
        const checkbox = listItem.querySelector("input[type='checkbox']").checked = false
      }
    })
  }

  validateEmail(email) {
    if (/^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)) {
      return true
    } else {
      return false
    }
  }

  recipientExists(email){
    for(const hiddenField of this.hiddenRecipientFieldTargets){
      if (hiddenField.value == email){
        return true
      }
    }
  }

  closeAutocomplete() {
    let results = this.element.querySelector("[data-autocomplete-target='results']")
    if (results) {
      results.hidden = true
    }
  }

  insertRecipientFromList(e) {
    const container = e.currentTarget
    const email = container.dataset.email
    const checkbox = container.querySelector("input[type='checkbox']")

    if (checkbox.checked) {
      this.removeRecipientFromList(email)
    } else {
      this.insertRecipient(email)
    }
  }

  removeRecipientFromList(email) {
    const field = this.element.querySelector(`[title='${email}']`)

    field.remove()

    for(const hiddenField of this.hiddenRecipientFieldTargets) {
      if (hiddenField.value == email) {
        hiddenField.remove()
      }
    }
  }
}
