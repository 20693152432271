import { Controller } from "@hotwired/stimulus"
import Trix from "trix"

export default class extends Controller {
  static targets = ["field", "selectInsertion", "options"]
  static values = { available: Object, customAttachmentUrl: String }

  connect() {
    this.addToUI()
  }

  getContentForGroup(e) {
    const selectedGroup = e.target.value

    if (!selectedGroup) return

    const availableContent = e.target.options[e.target.selectedIndex].dataset.dynamicContent || {}
    this.availableValue = JSON.parse(availableContent)

    this.addToUI()
  }

  addToUI() {
    if (this.hasSelectInsertionTarget) {
      let contentSelect = `<div class="action-bar"">
                            <div class="button button--plain dropdown action-bar-link" data-toggle="dropdown" data-action="mousedown->dynamic-content#storeCursorPosition">
                              Dynamic Content &#9660;
                            </div>
                            <div data-dynamic-content-target="options" class="dropdown-menu dropdown-menu--dynamic-content">`

      for(const [key, value] of Object.entries(this.availableValue)){
        contentSelect += `<a href="#" class="dropdown-item" data-unstyled="true" data-action="click->dynamic-content#insert" data-dynamic-content-value="${value}">${key}</a>`
      }

      contentSelect += `</div></div>`
      this.selectInsertionTarget.innerHTML = contentSelect
    }
  }

  insert(e) {
    e.preventDefault()

    let content = e.target.dataset.dynamicContentValue
    e.target.value = ""

    const isACustomAttachment = content.includes("_attachment")
    const withinTrixEditor = this.fieldToInsert.tagName == "TRIX-EDITOR"

    if (isACustomAttachment && !withinTrixEditor) return

    if (this.fieldToInsert.dataset.dynamicContentTarget == "field") {
      if (withinTrixEditor) {
        if (isACustomAttachment) {
          this.createAttachment(content)
        } else {
          this.fieldToInsert.editor.insertString(content)
        }
      } else {
        this.insertAtCursor(content)
      }

      this.fieldToInsert.focus()
    }

    this.optionsTarget.classList.remove("show")
  }

  createAttachment(content) {
    const attachmentId = content.replace("_attachment", "")

    fetch(`${this.customAttachmentUrlValue}?id=${attachmentId}`)
      .then(response => response.json())
      .then(attachmentData => this.insertAttachment(attachmentData))
  }

  insertAttachment(attachmentData) {
    const attachment = new Trix.Attachment({
      sgid: attachmentData["sgid"],
      content: attachmentData["content"],
      contentType: attachmentData["content_type"],
      filename: attachmentData["filename"],
    })

    this.fieldToInsert.editor.insertAttachment(attachment)
  }

  insertAtCursor(content) {
    let field = this.fieldToInsert

    if (document.selection) {
      field.focus()
      sel = document.selection.createRange()
      sel.text = content
    } else if (field.selectionStart || field.selectionStart == '0') {
      let startPos = field.selectionStart
      let endPos = field.selectionEnd
      field.value = field.value.substring(0, startPos)
                      + content
                      + field.value.substring(endPos, field.value.length)
      field.selectionStart = startPos + content.length
      field.selectionEnd = startPos + content.length
    } else {
      field.value += content
    }
  }

  storeCursorPosition(e) {
    e.preventDefault()
    this.fieldToInsert = document.activeElement
    this.cursorPosition = this.fieldToInsert.selectionStart
  }

  storeInputSelection(e) {
    e.target.dataset.previouslySelectedValue = e.target.value
  }

  confirmInputChange(e) {
    const previouslySelectedValue = e.target.dataset.previouslySelectedValue

    if (!previouslySelectedValue.length || previouslySelectedValue == "other") {
      e.target.dataset.previouslySelectedValue = e.target.value
      return
    }

    if (confirm("Changing this value will reset the form. Are you sure you want to continue?") === false) {
      e.target.value = e.target.dataset.previouslySelectedValue
    } else {
      this.storeInputSelection
      this.fieldTargets.forEach(field => {
        field.value = ""
      })
    }
  }
}
