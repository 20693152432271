import { Controller } from "@hotwired/stimulus"
import Money from 'src/dinero-custom'

export default class extends Controller {
  static targets = ['enteredAmount', 'totalApplied', 'creditAmount', 'totalPayment', 'paymentGroup', 'paymentRequiredGroup', 'paymentRequired', 'paymentApplication', 'paymentApplicationAmount']
  static values = { appliedAmount: Number, unappliedAmount: Number, applicationErrors: Number, creditAmount: Number, allowOverpayment: Boolean, controlPaymentGroups: Boolean  }

  connect() {
    this.appliedAmountValue ||= 0
    this.unappliedAmountValue = this.enteredAmountTarget.value
    this.applicationErrorsValue = 0
  }

  processEntry() {
    this.selectPaymentRequiredGroup()
    this.clearGroup()
    this.applyAmountToItems(this.paymentRequiredTargets, this.enteredAmountTarget.value)
  }

  selectPaymentRequiredGroup() {
    if (this.hasPaymentRequiredGroupTarget ){
      let isNotChecked = !this.paymentRequiredGroupTarget.checked
      if( isNotChecked ){
        this.feeVisibilityController.show()
        this.paymentRequiredGroupTarget.checked = 'checked'
      }
    }
  }

  paymentMethodChange(e) {
    let selectedPaymentMethod = e.currentTarget.value
    let paymentMethod = document.getElementById('payment_payment_method')
    paymentMethod.value = selectedPaymentMethod
  }

  categoryChange(e) {
    this.enablePaymentApplicationGroupBoxes()

    let selectedCategory = e.currentTarget.value
    let form = document.getElementById('payment_form')

    let cashContainer = document.getElementById('cash_container')
    let creditContainer = document.getElementById('credit_container')
    let paymentCategory = document.getElementById('payment_category')
    let paymentMethod = document.getElementById('payment_payment_method')

    let overPaymentSetting = e.currentTarget.hasAttribute('data-allow-overpayment')

    switch(selectedCategory){
      case 'credit':
        this.allowOverpaymentValue = overPaymentSetting
        this.resetEnteredAmount()
        form.setAttribute('data-remote', 1);
        form.removeAttribute('data-controller', 'stripe');
        cashContainer.classList.add('hidden')
        creditContainer.classList.add('hidden')
        paymentCategory.value = 'payment'
        paymentMethod.value = 'balance'
        break
      case 'charge':
        this.allowOverpaymentValue = overPaymentSetting
        this.resetEnteredAmount()
        this.clearOverPaymentErrors()
        this.clearOverPaymentWithCreditBalanceErrors()
        form.removeAttribute('data-remote')
        form.setAttribute('data-controller', 'stripe');
        cashContainer.classList.add('hidden')
        creditContainer.classList.remove('hidden')
        paymentCategory.value = 'charge'
        paymentMethod.value = 'credit_card'
        break
      default:
        this.allowOverpaymentValue = overPaymentSetting
        this.resetEnteredAmount()
        this.clearOverPaymentErrors()
        this.clearOverPaymentWithCreditBalanceErrors()
        form.setAttribute('data-remote', 1);
        form.removeAttribute('data-controller', 'stripe');
        cashContainer.classList.remove('hidden')
        creditContainer.classList.add('hidden')
        paymentCategory.value = 'payment'
        paymentMethod.value = 'check'
    }
  }

  processGroup(e) {
    let selectedTerm = e.currentTarget
    let paymentGroup = document.getElementById(selectedTerm.getAttribute('data-target-fields'))

    if (selectedTerm.checked) {
      this.applyAmountToItems(paymentGroup.querySelectorAll("input[data-payment-application-type='CustomerFee']"), this.unappliedAmountValue)
    } else {
      this.clearPaymentAllocations(paymentGroup)
    }
  }

  processCurrentDue(e){
    let amount = e.currentTarget.getAttribute('data-payment-amount')
    this.enteredAmountTarget.value = amount

    let paymentGroups = this.paymentGroupTargets.filter( function (paymentGroup){
      return paymentGroup.hasAttribute('data-payment-group-current-due')
    })

    let paymentTargets = this.paymentApplicationTargets.filter( function (paymentTarget){
      return paymentTarget.hasAttribute('data-payment-current-due')
    })

    this.processItems(paymentGroups, paymentTargets, this.enteredAmountTarget.value)
  }

  processTotalBalance(e){
    let amount = e.currentTarget.getAttribute('data-payment-amount')
    this.enteredAmountTarget.value = amount
    this.processItems(this.paymentGroupTargets, this.paymentApplicationTargets, this.enteredAmountTarget.value)
  }

  processOtherAmount(e){
    // let amount = e.currentTarget.getAttribute('data-payment-amount')
    // this.enteredAmountTarget.value = amount
    this.resetEnteredAmount()
    this.disablePaymentButton()
  }

  processItems(groupTargets, paymentTargets, amount){
    this.processEntry()

    groupTargets.forEach(paymentGroup =>{
      if( !paymentGroup.checked ){
        paymentGroup.click()
      }
    })

    this.applyAmountToItems(paymentTargets, amount)
  }

  clearGroup() {
    this.paymentGroupTargets.forEach(paymentGroup => {
      if( paymentGroup.checked){
        paymentGroup.click()
      }else{
        this.clearPaymentAllocations(paymentGroup)
      }
    })
  }

  resetEnteredAmount(){
    this.enteredAmountTarget.value = '0.00'
    this.appliedAmountValue = 0
    this.unappliedAmountValue = 0
    this.applicationErrorsValue = 0
    this.disablePaymentButton
    this.clearGroup()
  }

  resetForm(e) {
    this.appliedAmountValue = 0
    this.unappliedAmountValue = 0
    this.applicationErrorsValue = 0

    if( !!this.feeVisibilityController ){
      this.feeVisibilityController.hide()
    }

    this.clearGroup()
    e.currentTarget.closest('form').reset()
  }

  autoApply(e) {
    e.preventDefault()
    let selectedGroup = e.currentTarget
    let paymentGroup = document.getElementById(selectedGroup.getAttribute('data-target-fields'))
    this.applyAmountToItems(paymentGroup.querySelectorAll("input[data-payment-application-type='Invoice']"), this.unappliedAmountValue)
  }

  evenApply(e){
    e.preventDefault()
    let selectedGroup = e.currentTarget
    let paymentGroup = document.getElementById(selectedGroup.getAttribute('data-target-fields'))
    this.evenlyApplyAmountToItems(paymentGroup.querySelectorAll("input[data-payment-application-type='Invoice']"), this.unappliedAmountValue)
  }

  clearPaymentAllocations(paymentGroup) {
    paymentGroup.querySelectorAll("input[data-payment-target='paymentApplication']").forEach(paymentApplication => {
      paymentApplication.value = '0.00'
      this.paymentApplicationRemoveError(paymentApplication)
    })

    this.updateAmounts()
  }

  applyAmountToItems(items, availableAmount){
    let unallocatedAmount = new Money({ amount: availableAmount })

    items.forEach(item => {
      let feeBalance = new Money({ amount: item.getAttribute('data-balance') })
      let allocationAmount = this.amountToAllocate(unallocatedAmount, feeBalance).toInput()

      unallocatedAmount = unallocatedAmount.subtract(allocationAmount)
      item.value = allocationAmount
    })

    this.updateAmounts()
  }

  evenlyApplyAmountToItems(items, availableAmount) {
    let unallocatedAmount = new Money({ amount: availableAmount });

    let allocationAmounts = unallocatedAmount.split(items.length);

    items.forEach((item, index) => {
      let feeBalance = new Money({ amount: item.getAttribute('data-balance') })
      let amount = allocationAmounts[index] || new Money( {amount: 0} )

      let allocationAmount = this.amountToAllocate(amount, feeBalance).toInput()

      unallocatedAmount = unallocatedAmount.subtract(allocationAmount)
      item.value = allocationAmount
      this.verifyPaymentApplication(item)
    })

    this.updateAmounts()
  }

  updateAmounts() {
    let enteredAmount = new Money({ amount: this.enteredAmountTarget.value })
    let amountApplied = new Money( {amount: 0} )
    let amountAppliedToInvoice = new Money( {amount: 0} )

    this.paymentApplicationTargets.forEach(item => {
      if ( item.value > 0 ) {
        amountApplied = amountApplied.add(item.value)

        if ( item.getAttribute('data-payment-application-type') == 'Invoice'){
          amountAppliedToInvoice = amountAppliedToInvoice.add(item.value)
        }
      }
    })

    this.appliedAmountValue = amountApplied.toInput()

    let amountUnapplied = enteredAmount.subtract(this.appliedAmountValue)
    this.unappliedAmountValue = amountUnapplied.toInput()

    if ( this.hasPaymentApplicationAmountTarget ){
      this.paymentApplicationAmountTarget.value = amountAppliedToInvoice.toInput()
    }

    if ( this.hasTotalPaymentTarget ){
      this.totalPaymentTarget.textContent = new Money({ amount: this.appliedAmountValue + this.unappliedAmountValue }).toText()
    }
  }

  checkApplicationPayment(e) {
    this.verifyPaymentApplication(e.currentTarget)
  }

  verifyPaymentApplication(paymentApplication) {
    let entryAmount = parseFloat(paymentApplication.value)
    let balanceAmount = parseFloat(paymentApplication.getAttribute('data-balance'))

    if ( entryAmount > balanceAmount ) {
      if ( !paymentApplication.hasAttribute('data-payment-error') ) {
        this.disablePaymentButton()
        this.paymentApplicationAddError(paymentApplication, 'Must be less than balance.')
      }
    } else if ( entryAmount < 0 ) {
      if ( !paymentApplication.hasAttribute('data-payment-error') ) {
        this.disablePaymentButton()
        this.paymentApplicationAddError(paymentApplication, 'Can not be a negative amount.')
      }
    } else {
      this.enablePaymentButton()
      this.paymentApplicationRemoveError(paymentApplication)
      this.unappliedAmountValueChanged()
    }
  }

  appliedAmountValueChanged() {
    let appliedAmount = new Money({ amount: this.appliedAmountValue })
    this.totalAppliedTarget.textContent = appliedAmount.toText()
  }

  unappliedAmountValueChanged() {
    let unappliedAmount = new Money({ amount: this.unappliedAmountValue })

    if (this.overPayment) {
      this.overAppliedContainer.classList.add('hidden')
      this.overPaymentHandler(unappliedAmount)
      this.enableUnappliedGroup(unappliedAmount)
    } else if(this.overApplied) {
      this.disableUnappliedGroup()
      this.disablePaymentButton()
      this.overAppliedContainer.classList.remove('hidden')
      this.overPaymentContainer.classList.add('hidden')
    } else if(this.zeroEntry) {
      this.disablePaymentButton()
      this.disableUnappliedGroup()
      this.overPaymentContainer.classList.add('hidden')
    } else {
      this.disableUnappliedGroup()
      this.enablePaymentButton()
      this.clearOverPaymentWithCreditBalanceErrors()
      this.overAppliedContainer.classList.add('hidden')
      this.overPaymentContainer.classList.add('hidden')
    }
  }

  enableUnappliedGroup(unappliedAmount) {
    if ( this.unappliedContainer ){
      this.unappliedContainer.classList.remove('hidden')
      this.unappliedEntry.textContent = unappliedAmount.toText()
    }
  }

  disableUnappliedGroup() {
    if ( this.unappliedContainer ){
      this.unappliedContainer.classList.add('hidden')
      this.unappliedEntry.textContent = '0.00'
    }
  }

  overPaymentHandler(unappliedAmount) {
    this.allowOverpaymentValue ? this.overPaymentWarn(unappliedAmount) : this.overPaymentProcess()
  }

  overPaymentProcess() {
    if ( this.overCreditAvailable ) {
      this.disablePaymentButton()
      this.setOverPaymentErrors()
      this.disablePaymentApplicationGroupBoxes()
      this.clearOverPaymentWithCreditBalanceErrors()
    } else if ( this.overPaymentWithCreditBalance ) {
      this.disablePaymentButton()
      this.clearOverPaymentErrors()
      this.setOverPaymentWithCreditBalanceErrors()
      this.enablePaymentApplicationGroupBoxes()
    } else {
      this.enablePaymentButton()
      this.clearOverPaymentErrors()
      this.clearOverPaymentWithCreditBalanceErrors()
      this.enablePaymentApplicationGroupBoxes()
    }
  }

  overPaymentWarn(unappliedAmount) {
    this.overPaymentContainer.classList.remove('hidden')
    this.overPaymentAmountDisplay.textContent = unappliedAmount.toText()

    if (this.confirmCreditContainer) {
      this.disablePaymentButton()
      this.confirmCreditContainer.classList.remove('hidden')
      this.creditConfirmedContainer.classList.add('hidden')
      this.creditAmountTarget.textContent = new Money({ amount: 0 }).toText()
      this.totalPaymentTarget.textContent = new Money({ amount: 0 }).toText()
    } else {
      this.enablePaymentButton()
    }
  }

  confirmOverPaymentToCredit(e) {
    e.preventDefault()

    if (this.confirmCreditContainer) {
      this.enablePaymentButton()
      this.confirmCreditContainer.classList.add('hidden')
      this.creditConfirmedContainer.classList.remove('hidden')
      this.creditAmountTarget.textContent = new Money({ amount: this.unappliedAmountValue }).toText()
      this.totalPaymentTarget.textContent = new Money({ amount: this.appliedAmountValue + this.unappliedAmountValue }).toText()
    }
  }

  setOverPaymentErrors() {
    let enteredAmountContainer = this.enteredAmountTarget.closest('.input-wrapper')
    let enteredAmountError = document.getElementById('error-for-entered-amount')

    if ( this.enteredAmountTarget.hasAttribute('data-display-overpayment-error') ){
      this.enteredAmountTarget.classList.add('field-message-error')
      enteredAmountContainer.classList.add('field_with_errors')
      enteredAmountError.classList.remove('hidden')
    }
  }

  clearOverPaymentErrors() {
    let enteredAmountContainer = this.enteredAmountTarget.closest('.input-wrapper')
    let enteredAmountError = document.getElementById('error-for-entered-amount')

    if ( this.enteredAmountTarget.hasAttribute('data-display-overpayment-error') ){
      enteredAmountError.classList.add('hidden')
      enteredAmountContainer.classList.remove('field_with_errors')
      this.enteredAmountTarget.classList.remove('field-message-error')
    }
  }

  setOverPaymentWithCreditBalanceErrors() {
    let errorMessage = document.getElementById('overpayment-credit-balance-container')

    if (errorMessage != null) {
      errorMessage.classList.remove('hidden')
    }
  }

  clearOverPaymentWithCreditBalanceErrors() {
    let errorMessage = document.getElementById('overpayment-credit-balance-container')

    if (errorMessage != null) {
      errorMessage.classList.add('hidden')
    }
  }

  amountToAllocate(unallocatedAmount, requestedAmount) {
    return unallocatedAmount.amount >= requestedAmount.amount ? requestedAmount : unallocatedAmount
  }

  enablePaymentButton() {
    let enteredAmount = new Money({ amount: this.enteredAmountTarget.value })

    if( (this.applicationErrorsValue == 0) && enteredAmount.toInput() > 0 ) {
      this.payButton.disabled = false
      this.payButton.classList.remove('button--disabled')
    }
  }

  disablePaymentButton() {
    this.payButton.disabled = true
    this.payButton.classList.add('button--disabled')
  }

  enablePaymentApplicationGroupBoxes() {
    if (this.controlPaymentGroupsValue) {
      this.paymentGroupTargets.forEach(paymentGroup => {
        paymentGroup.parentElement.parentElement.classList.remove("text--subdued")
        paymentGroup.disabled = false
        paymentGroup.querySelectorAll('button, input').forEach(element => {
          element.disabled = false
        })
      })
    }
  }

  disablePaymentApplicationGroupBoxes() {
    if (this.controlPaymentGroupsValue) {
      this.paymentGroupTargets.forEach(paymentGroup => {
        paymentGroup.parentElement.parentElement.classList.add("text--subdued")
        paymentGroup.disabled = true
        paymentGroup.querySelectorAll('button, input').forEach(element => {
          element.disabled = true
        })
      })
    }
  }

  removeElements(elementList){
    elementList.forEach(element => {
      element.remove()
    })
  }

  paymentApplicationAddError(paymentApplication, error) {
    this.applicationErrorsValue = this.applicationErrorsValue + 1

    let paymentApplicationContainer = paymentApplication.closest('.input-wrapper')
    paymentApplicationContainer.classList.add('field_with_errors')
    let span = document.createElement('span')
    span.classList.add('field-error-message')
    span.textContent = error
    paymentApplicationContainer.appendChild(span)
    paymentApplication.setAttribute('data-payment-error', '')
  }

  paymentApplicationRemoveError(paymentApplication) {
    let paymentApplicationContainer = paymentApplication.closest('.input-wrapper')
    paymentApplication.removeAttribute('data-payment-error')
    paymentApplicationContainer.classList.remove('field_with_errors')
    this.removeElements(Array.from(paymentApplicationContainer.getElementsByClassName('field-error-message')))

    this.applicationErrorsValue = this.countPaymentApplicationErrors
  }

  get overPayment() {
    return this.unappliedAmountValue > 0
  }

  get overApplied() {
    return this.unappliedAmountValue < 0
  }

  get countPaymentApplicationErrors() {
    let count = 0;

    this.paymentApplicationTargets.forEach(item => {
      if ( item.hasAttribute('data-payment-error') ) {
        count = count + 1;
      }
    })

    return count
  }

  get overCreditAvailable() {
    return this.enteredAmountTarget.value > this.creditAmountValue
  }

  get overPaymentWithCreditBalance() {
    let paymentMethod = document.getElementById('payment_payment_method')

    return (paymentMethod.value == 'balance' && this.enteredAmountTarget.value > this.appliedAmountValue)
  }

  get zeroEntry() {
    let enteredAmount = new Money({ amount: this.enteredAmountTarget.value })

    return enteredAmount.toInput() == 0
  }

  get feeVisibilityController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById('fee-visibility-container'), 'visibility')
  }

  get selectedPaymentType() {
    return document.querySelector("input[name='payment[payment_type]']:checked")
  }

  get overAppliedContainer() {
    return document.getElementById('over-applied-container')
  }

  get overPaymentContainer() {
    return document.getElementById('overpayment-container')
  }

  get overPaymentAmountDisplay() {
    return document.getElementById('overpayment-amount-display')
  }

  get overPaymentAmountInput(){
    return document.getElementById('overpayment-amount')
  }

  get confirmCreditContainer() {
    return document.getElementById('confirm-credit-container')
  }

  get creditConfirmedContainer() {
    return document.getElementById('credit-confirmed-container')
  }

  get unappliedContainer() {
    return document.getElementById('unapplied-container')
  }

  get unappliedEntry() {
    return document.getElementById('unapplied-entry')
  }

  get payButton() {
    return document.querySelector('button[type=submit][data-payment-submit="1"]')
  }
}
