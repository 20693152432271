import { Controller } from "@hotwired/stimulus"
import moment from "moment"
import Pikaday from "pikaday"

export default class extends Controller {
  static targets = ["fromField", "toField", "rangeLimiter", "disabledFields",
                    "enabledFields", "disabledFromField", "disabledToField", "datePicker"]

  static values = { rangeLimits: Object, pickerDisabled: Boolean }

  connect() {
    this.createDatePickers()

    if (this.numCheckedRangeLimiters() > 0) { this.showDisabledFields() }
  }

  performLimitChange() {
    let fromValues = []
    let toValues = []

    for (let rangeLimiter of this.rangeLimiterTargets) {
      if (rangeLimiter.checked) {
        let range = JSON.parse(rangeLimiter.dataset.datePickerRangeLimit)
        fromValues.push(moment(range.from))
        toValues.push(moment(range.to))
      }
    }

    if (this.numCheckedRangeLimiters() == 0) {
      if (this.pickerDisabledValue) {
        this.disabledFieldsTarget.classList.add("hidden")
        this.enabledFieldsTarget.classList.add("hidden")
      }
      return
    } else {
      this.disabledFieldsTarget.classList.remove("hidden")
      this.enabledFieldsTarget.classList.add("hidden")
    }

    let minFrom = moment(Math.min(...fromValues)).format("YYYY-MM-DD")
    let maxTo = moment(Math.max(...toValues)).format("YYYY-MM-DD")

    this.rangeLimitsValue = { from: minFrom, to: maxTo }

    this.limitDateRanges()
  }

  limitDateRanges() {
    const fromLimitDate = moment(this.rangeLimitsValue.from).toDate()
    const toLimitDate = moment(this.rangeLimitsValue.to).toDate()

    this.datePickers["from"].setDate(fromLimitDate)
    this.datePickers["to"].setDate(toLimitDate)

    this.fromFieldTarget.value = this.rangeLimitsValue.from
    this.toFieldTarget.value = this.rangeLimitsValue.to

    this.datePickers["from"].setMinDate(fromLimitDate)
    this.datePickers["from"].setMaxDate(toLimitDate)
    this.datePickers["to"].setMinDate(fromLimitDate)
    this.datePickers["to"].setMaxDate(toLimitDate)

    if (this.pickerDisabledValue) {
      this.disabledFromFieldTarget.innerHTML = this.rangeLimitsValue.from
      this.disabledToFieldTarget.innerHTML = this.rangeLimitsValue.to

      this.disabledFieldsTarget.classList.remove("hidden")
    }
  }

  showEnabledFields(e) {
    e.preventDefault()
    this.disabledFieldsTarget.classList.add("hidden")
    this.enabledFieldsTarget.classList.remove("hidden")
  }

  showDisabledFields() {
    this.disabledFieldsTarget.classList.remove("hidden")
  }

  createDatePickers() {
    this.datePickers = {}

    for (const datePickerField of this.datePickerTargets) {
      const type = datePickerField.dataset.datePickerType

      const datepicker = new Pikaday({
        field: datePickerField
      })

      this.datePickers[type] = datepicker
    }
  }

  numCheckedRangeLimiters() {
    let numChecked = 0

    for (let rangeLimiter of this.rangeLimiterTargets) {
      if (rangeLimiter.checked) { numChecked += 1 }
    }

    return numChecked
  }
}
