import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dropdown', 'fieldset', 'textbox', 'checkbox', 'checkboxLabel', 'hiddenField', 'actionField', 'actionCheckbox', 'submitButton']

  hiddenClass = 'hidden';
  disabledButtonClass = 'button--disabled'
  disabledTextClass = 'text--subdued'

  connect() {
    this.onSelect = this.onSelect.bind(this)
    this.onInput = this.onInput.bind(this)
  }

  disconnect() {
    if (this.dropdownTargets.length) {
      this.dropdownTargets.forEach(dropdown => {
        dropdown.removeEventListener("change", this.onSelect)
      })
    }

    if (this.textboxTargets.length) {
      this.textboxTargets.forEach(textbox => {
        textbox.removeEventListener("input", this.onInput)
      })
    }
  }

  onSelect(event) {
    let option = event.target.options[event.target.selectedIndex]
    
    if (option.text == "Select an option") {
      this.unfill() 
    } else {
      this.fill(option)
    }
  }

  onInput(event) {
    if (event.target.value != "") {
      this.submitButtonTarget.classList.remove(this.disabledButtonClass)
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.classList.add(this.disabledButtonClass)
      this.submitButtonTarget.disabled = true
    }
  }

  fill(option) {
    let message = option.value
    let addFee = option.dataset.addReturnPaymentFee == "true"

    this.fieldsetTarget.classList.remove(this.hiddenClass)
    this.textboxTarget.disabled = false

    this.textboxTarget.value = message
    this.checkboxTarget.checked = addFee
    this.resetInput()

    if (option.text == "Other") { this.fillOtherOption() } else { this.fillOption(option, addFee)}

    if (message != "") {
      this.submitButtonTarget.classList.remove(this.disabledButtonClass)
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.classList.add(this.disabledButtonClass)
      this.submitButtonTarget.disabled = true
    }
  }

  unfill() {
    this.fieldsetTarget.classList.add(this.hiddenClass)
    this.textboxTarget.disabled = true
    this.checkboxTarget.disabled = true
    this.checkboxLabelTarget.classList.add(this.disabledTextClass)
    this.resetInput()

    this.submitButtonTarget.classList.add(this.disabledButtonClass)
    this.submitButtonTarget.disabled = true
  }

  resetInput() {
    this.actionCheckboxTargets.forEach(checkbox => {
      checkbox.classList.add(this.disabledTextClass)
      checkbox.querySelectorAll("input").forEach(input => { 
        input.disabled = true
        input.checked = false
      })
    })

    this.actionFieldTargets.forEach(field => { field.disabled = true })
  }

  // Check checkboxes and enable hidden fields of the actions listed in the option's [data-actions] attribute
  fillOption(option, addFee) {
    this.checkboxTarget.disabled = true
    this.checkboxLabelTarget.classList.add(this.disabledTextClass)
    
    this.hiddenFieldTarget.disabled = !addFee

    JSON.parse(option.dataset.actions).forEach(action => {
      this.fieldsetTarget.querySelectorAll("[data-payment-return-action-value='" + action + "']").forEach(input => {
        if (input.type == "hidden") { input.disabled = false }
        if (input.tagName == "LABEL") { input.querySelectorAll("input").forEach(input => { input.checked = true }) }
      })
    })
  }

  // Enables all checkboxes and disables all hidden fields
  fillOtherOption() {
    this.checkboxTarget.disabled = false
    this.checkboxLabelTarget.classList.remove(this.disabledTextClass)
    this.hiddenFieldTarget.disabled = true

    this.actionCheckboxTargets.forEach(checkbox => {
      checkbox.classList.remove(this.disabledTextClass)
      checkbox.querySelectorAll("input").forEach(input => { input.disabled = false })
    })
  }
}
