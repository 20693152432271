import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "field", "remainingAmountHeader", "remainingAmountText", "submitButton"]
  static values = { availableAmount: Number, assignedAmount: Number }

  toggleField(event) {
    let field = document.querySelector(`[data-input-counter-target='field'][data-id='${event.currentTarget.dataset.id}']`)

    if (field.disabled) {
      field.disabled = false
    }
    else {
      field.value = 0
      field.disabled = true
    }

    this.adjustRemainingAmount()
    this.toggleSubmitButton()
  }

  adjustAmount(event) {
    this.adjustRemainingAmount()
    this.toggleSubmitButton()
  }

  adjustRemainingAmount() {
    this.assignedAmountValue = 0
    this.fieldTargets.forEach(field => {
      this.assignedAmountValue += parseInt(field.value || 0)
    })

    let remainingAmount = this.availableAmountValue - this.assignedAmountValue
    this.remainingAmountTextTarget.innerHTML = remainingAmount
    
    if (remainingAmount < 0) {
      this.remainingAmountHeaderTarget.classList.add("red-text")
      this.remainingAmountTextTarget.classList.add("red-text")
    }
    else {
      this.remainingAmountHeaderTarget.classList.remove("red-text")
      this.remainingAmountTextTarget.classList.remove("red-text")
    }
  }

  toggleSubmitButton() {
    if (this.assignedAmountValue == this.availableAmountValue) {
      this.submitButtonTarget.classList.remove("button--disabled")
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.classList.add("button--disabled")
      this.submitButtonTarget.disabled = true
    }
  }
}
