import Dinero from "dinero.js"

export default class Money {
  constructor({ amount = 0, precision = 2, cents = false }) {
    this.amount = Money.convertToCents(amount, precision, cents);
    this.precision = precision;
    this.cents = cents;
  }

  static convertToCents(amount, precision, cents) {
    if (cents) { return amount; }
    if (isNaN(amount)) { return 0; }

    amount = Money.convertToMinimalUnit(Number(amount.toString().replace(",", "")), precision)

    return amount
  }

  static convertToMinimalUnit(amount, precision) {
    return Math.round(amount * Math.pow(10, precision));
  }

  add(input, cents = false) {
    let inputAmount = cents ? parseInt(input) : Money.convertToCents(input, 2, false)
    let inputDinero = Dinero({ amount: inputAmount, precision: 2 })

    return new Money({ amount: this.toDinero().add(inputDinero).getAmount(), cents: true })
  }

  subtract(input, cents = false) {
    let inputAmount = cents ? parseInt(input) : Money.convertToCents(input, 2, false)
    let inputDinero = Dinero({ amount: inputAmount, precision: 2 })

    return new Money({ amount: this.toDinero().subtract(inputDinero).getAmount(), cents: true })
  }

  multiply(multiplier) {
    return new Money({ amount: this.toDinero().multiply(multiplier, HALF_UP).getAmount(), cents: true })
  }

  percentage(rate) {
    return new Money({ amount: this.toDinero().percentage(rate, 'HALF_UP').getAmount(), cents: true})
  }

  split(parts) {
    let ratios = new Array(parts).fill(1/parts)

    var money_parts = this.toDinero().allocate(ratios).map(function (allocation) {
      return new Money({ amount: allocation.getAmount(), cents: true})
    });

    return money_parts
  }

  toDinero() {
    return Dinero({ amount: this.amount, precision: this.precision })
  }

  toInput(format = '0.00') {
    return this.toDinero().toFormat(format)
  }

  toText(format = '$0,0.00') {
    return this.toDinero().toFormat(format)
  }
}
