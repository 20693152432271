import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item']

  toggle(event) {
    let token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
    let url = event.currentTarget.getAttribute('data-checklist-url')

    fetch(url, {
      method: 'PATCH',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
        "Accept": "text/javascript"
      },
      body: JSON.stringify()
    }).then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }
}
