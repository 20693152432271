import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item', 'disabledToggle', 'disable', 'selectField', 'checkboxGroup']

  hiddenClass = 'hidden';

  initialize () {
    if (this.performOnConnect) {
      if (this.hasSelectFieldTarget) {
        this.toggleFromSelect()
      }
    }
  }

  toggle () {
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.hiddenClass)
    })

    this.toggleDisabledItems()
  }

  show () {
    this.itemTargets.forEach(item => {
      item.classList.remove(this.hiddenClass)
    })

    this.toggleDisabledItems()
    this.enableItems()
  }

  hide () {
    this.itemTargets.forEach(item => {
      item.classList.add(this.hiddenClass)
    })

    this.toggleDisabledItems()
    this.disableItems()
  }

  toggleDisabledItems () {
    this.disabledToggleTargets.forEach(item => {
      item.toggleAttribute('disabled')
    })
  }

  disableItems () {
    this.disableTargets.forEach(item => {
      item.value = ""
      item.disabled = true
    })
  }

  enableItems () {
    this.disableTargets.forEach(item => {
      item.disabled = false
    })
  }

  toggleFromSelect (e) {
    let selectedValue = this.selectFieldTarget.value || ""

    this.itemTargets.forEach(item => {
      let showValues = this.stringToArray(item.dataset.visibilityShowValues)
      let shouldShow = selectedValue.length && showValues.indexOf(selectedValue) > -1
      let shouldShowUnselected = selectedValue == "" && showValues.length == 0

      if ((e == null) && (item.dataset.visibilityHideOnConnect)) {
        item.classList.add(this.hiddenClass)
      } else if (shouldShow || shouldShowUnselected) {
        item.classList.remove(this.hiddenClass)
        item.querySelectorAll("input").forEach(input => {
          input.disabled = false
        })
      } else {
        item.classList.add(this.hiddenClass)
        item.querySelectorAll("input").forEach(input => {
          input.disabled = true
        })
      }
    })
  }

  toggleFromRadio (e) {
    let destination = e.target.dataset.visibilityDestination

    this.itemTargets.forEach(item => {
      if (item.dataset.visibilityToggle == destination) {
        item.classList.remove(this.hiddenClass)
        item.disabled = false
      } else {
        item.classList.add(this.hiddenClass)
        item.disabled = true
      }
    })
  }

  toggleFromCheckbox (e) {
    let destination = e.target.dataset.visibilityDestination
    let requirement = e.target.dataset.visibilityRequirement == "true"

    this.itemTargets.forEach(item => {
      if (item.dataset.visibilityToggle == destination && e.target.checked == requirement) {
        item.classList.remove(this.hiddenClass)
        item.disabled = false
      } else {
        item.classList.add(this.hiddenClass)
        item.disabled = true
      }
    })
  }

  toggleByCheckboxGroup(e) {
    const checkboxGroup = e.target.dataset.visibilityCheckboxGroup
    const destination = e.target.dataset.visibilityDestination
    const requirement = e.target.dataset.visibilityRequirement == "true"
    let requirementMet = true

    for(const checkbox of this.checkboxGroupTargets) {
      if (checkbox.dataset.visibilityCheckboxGroup !== checkboxGroup) continue

      if (checkbox.checked !== requirement) {
        requirementMet = false
        break
      }
    }

    for(const item of this.itemTargets) {
      if (requirementMet) {
        item.classList.remove(this.hiddenClass)
        item.disabled = false
      } else {
        item.classList.add(this.hiddenClass)
        item.disabled = true
      }
    }
  }

  stringToArray (text) {
    if (typeof text !== "string") {
      return []
    }

    try {
      let textArray = JSON.parse(text)
      return textArray
      
    } catch (error) {
      let textFormated = text.replace(/[\[\]']+/g, "")

      if (textFormated === "") {
        return []
      } else {
        return textFormated.split(", ")
      }
    }
  }

  get performOnConnect() {
    return this.data.get("performOnConnect") == "true"
  }
}
