import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.editor = this.element.querySelector("[data-trix-target='field']").editor
    this.preventUndeletableAttachmentRemoval()
  }

  preventUndeletableAttachmentRemoval() {
    this.element.addEventListener("trix-attachment-remove", (e) => {
      if (e.attachment.attachment.attributes.values.content.includes("undeletableAttachment")) {
        this.editor.undo()
      }
    })
  }
}
