import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = []
  static values = { key: String }

  connect() {
    this.stripe = Stripe(this.keyValue);
    this.elements = this.stripe.elements()
    this.mountCard()
  }

  disconnect(){
    this.unMountCard()
  }

  async tokenSubmit(e) {
    e.preventDefault();

    let name = document.getElementById('cardholder_name')

    let cardNumber = this.elements.getElement('cardNumber')
    let additionalData = { name: name ? name.value : undefined };

    let result = await this.stripe.createToken(cardNumber, additionalData);

    if (result.error) {
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = result.error.message;
    } else {
      this.tokenHandler(result.token);
    }
  }

  mountCard() {
    let style = {
      base: {
        color: '#212b36',
        fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    let cardNumber = this.elements.create('cardNumber', {style: style});
    cardNumber.mount('#card_number');
    this._stripeErrorHandler(cardNumber, 'error-for-number');

    let cardExpiry = this.elements.create('cardExpiry', {style: style});
    cardExpiry.mount('#card_expires');
    this._stripeErrorHandler(cardExpiry, 'error-for-expiration');

    let cardCvc = this.elements.create('cardCvc', {style: style});
    cardCvc.mount('#card_cvv');
    this._stripeErrorHandler(cardCvc, 'error-for-cvv');
  }

  unMountCard() {
    let cardNumber = this.elements.getElement('cardNumber')
    cardNumber.unmount()

    let cardExpiry = this.elements.getElement('cardExpiry')
    cardExpiry.unmount()

    let cardCvc = this.elements.getElement('cardCvc')
    cardCvc.unmount()
  }

  tokenHandler(token) {
    let hiddenDataFields = document.getElementById('hidden-data-fields')

    let hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'payment_token');
    hiddenInput.setAttribute('value', token.id);
    hiddenDataFields.appendChild(hiddenInput);

    let expHiddenInput = document.createElement('input');
    expHiddenInput.setAttribute('type', 'hidden');
    expHiddenInput.setAttribute('name', 'expires');
    expHiddenInput.setAttribute('value', token.card.exp_month + " / " + token.card.exp_year);
    hiddenDataFields.appendChild(expHiddenInput);

    let lastFourHiddenInput = document.createElement('input');
    lastFourHiddenInput.setAttribute('type', 'hidden');
    lastFourHiddenInput.setAttribute('name', 'last_four');
    lastFourHiddenInput.setAttribute('value', token.card.last4);
    hiddenDataFields.appendChild(lastFourHiddenInput);

    let brandHiddenInput = document.createElement('input');
    brandHiddenInput.setAttribute('type', 'hidden');
    brandHiddenInput.setAttribute('name', 'card_type');
    brandHiddenInput.setAttribute('value', this._formatCardBrand(token.card.brand));
    hiddenDataFields.appendChild(brandHiddenInput);

    this._submitForm()
  }

  _submitForm() {
    let form = document.getElementById('payment_form')
    Turbo.navigator.submitForm(form)
  }

  _removeTokenAttributes(){
    let hiddenDataFields = document.getElementById('hidden-data-fields')
    hiddenDataFields.querySelector("input[name=payment_token']").remove()
    hiddenDataFields.querySelector("input[name=expires']").remove()
    hiddenDataFields.querySelector("input[name=last_four']").remove()
    hiddenDataFields.querySelector("input[name=card_type']").remove()
  }

  _formatCardBrand(brand) {
    let formattedBrand = brand.toLowerCase();
    if (formattedBrand == 'american express') {
      formattedBrand = 'amex';
    }
    if (formattedBrand == 'diners club') {
      formattedBrand = 'dinersclub';
    }
    return formattedBrand;
  }

  _stripeErrorHandler(element, element_id) {
    element.addEventListener('change', function(event) {
      const displayError = document.getElementById(element_id);
      displayError.textContent = event.error ? event.error.message : ''
    });
  }

}
