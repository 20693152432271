import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = ["card"]
  static values = { url: String, placement: String }

  connect() {
    this.placementValue ||= "bottom"
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove()
    }
  }

  show() {
    if (this.hasCardTarget) {
      this.popperInstance.update()
      this.cardTarget.classList.remove("hidden")
    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => this.createPopper(html))
    }
  }

  createPopper(html) {
    if (this.hasCardTarget) return

    const fragment = document
                      .createRange()
                      .createContextualFragment(html)

    this.element.appendChild(fragment)

    const domElement = this.element.querySelector("[data-hovercard-target='card']")

    this.popperInstance = createPopper(this.element, domElement, { placement: this.placementValue })
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("hidden")
    } else {
      this.hideLater()
    }
  }

  hideLater() {
    const ele = this.element

    const observer = new MutationObserver(function(mutations, mutationInstance) {
      const card = ele.querySelector("[data-hovercard-target='card']")

      if (card) {
        card.classList.add("hidden")
        mutationInstance.disconnect();
      }
    })

    observer.observe(ele, {
      childList: true,
      subtree:   true
    })
  }
}
