import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "destination", "fillValue"]
  static values = { skipInitialSetup: Boolean, clearSource: Boolean }

  disabledClass = 'disabled-field'

  checkbox = this.hasSourceTarget ? this.sourceTarget.querySelector("input[type='checkbox']") : null

  connect() {
    if (this.skipInitialSetupValue == false){
      this.setInitialState()
    }
  }

  setInitialState() {
    if ((this.destinationTarget.value == this.value) && !this.newRecord && this.checkbox) {
      this.checkbox.checked = true
      this.destinationTarget.classList.add(this.disabledClass)
    }
  }

  perform() {
    this.checkbox.checked ? this.fill() : this.unfill()
  }

  fill() {
    this.destinationTarget.value = this.value
    this.destinationTarget.classList.add(this.disabledClass)
  }

  unfill() {
    this.destinationTarget.value = ""
    this.destinationTarget.classList.remove(this.disabledClass)
  }

  fillFromObject(e) {
    let url = e.target.selectedOptions[0].dataset.autoFillUrl

    if (url) {
      fetch(url, {
        headers:  {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
      })
      .then(response => response.json())
      .then(data => this.performObjectFill(data) )
    } else {
      for (const ele of this.destinationTargets) {
        ele.value = ""
      }
    }
  }

  performObjectFill(obj) {
    for (const [key, value] of Object.entries(obj)) {
      let destination = this.element.querySelector(`[data-auto-fill-key='${key}']`)

      if (destination && value) {
        if (["DIV", "SPAN", "SMALL"].includes(destination.tagName)) {
          destination.innerHTML = value
        } else {
          destination.value = value
          destination.addEventListener("dblclick", (e) =>
            e.target.select()
          )
        }
      }

      if (this.clearSourceValue) {
        this.sourceTarget.value = ""
      }
    }
  }

  fillFromRadio(e) {
    const val = e.target.dataset.autoFillValue

    this.destinationTarget.value = val
  }

  get value() {
    return this.data.get("value") || ""
  }

  get newRecord() {
    return this.data.get("newRecord") == "true"
  }
}
