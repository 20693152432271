import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["exposeIcon", "hideIcon", "maskedData", "unmaskedData"]
  static values = { unmaskedUrl: String, resourceId: Number }

  expose() {
    this.renderPartial()

    this.exposeIconTarget.classList.add("hidden")
    this.maskedDataTarget.classList.add("hidden")
    this.hideIconTarget.classList.remove("hidden")
  }

  hide() {
    this.unmaskedDataTarget.remove()
    this.exposeIconTarget.classList.remove("hidden")
    this.maskedDataTarget.classList.remove("hidden")
    this.hideIconTarget.classList.add("hidden")
  }

  renderPartial() {
    fetch(this.unmaskedUrlValue + `?resource_id=${ this.resourceIdValue }` )
      .then((response) => response.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html)

          this.maskedDataTarget.after(fragment)
      })
  }
}
