import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.open();
  }

  disconnect() {
    const backdrop = document.getElementsByClassName("modal-backdrop");
    if (backdrop[0]) {
      backdrop[0].remove();
    }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    document.body.insertAdjacentHTML("beforeend", '<div class="modal-backdrop fade show"></div>');
  }

  hide() {
    this.element.parentElement.removeAttribute("src")
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent click
    this.modalTarget.remove()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
  }

  submitEnd(event) {
    if (event.detail.success) {
      this.hide()
    }
  }

  closeWithKeyboard(event) {
    if (event.code == "Escape") {
      this.hide()
    }
  }

  closeBackground(event) {
    if (event && this.modalTarget.contains(event.target)) {
      return;
    }
    this.hide()
  }
}
