import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["copyable"]

  async copy(event) {
    event.preventDefault()
    this.reset()

    const success = await this.copyText(this.text)
  }

  reset() {
    this.forceReflow()
  }

  async copyText(text) {
    if ("clipboard" in navigator) {
      try {
        await navigator.clipboard.writeText(text)
        return true
      } catch {
        //  does not support the clipboard API fully and will throw an exception
      }
    }

    const node = this.createNode(text)
    document.body.append(node)
    const result = this.copyNode(node)
    node.remove()
    return result
  }

  createNode(text) {
    const node = document.createElement("pre")
    node.style = "width: 1px; height: 1px; position: fixed; top: 50%"
    node.textContent = text
    return node
  }

  copyNode(node) {
    const selection = document.getSelection()
    const range = document.createRange()
    range.selectNodeContents(node)
    selection.removeAllRanges()
    selection.addRange(range)
    return document.execCommand("copy")
  }

  forceReflow() {
    this.element.offsetWidth
  }

  get text() {
    const { value, textContent } = this.copyableTarget
    return value || textContent
  }
}
