import { Controller } from "@hotwired/stimulus"
import Money from "src/dinero-custom"

export default class extends Controller {
  static targets = ["cart", "total", "quantity", "itemContainer", "itemName", "submitBtn", "hiddenCheckbox"]
  static values = {}

  connect() {
    this.emptyCart()
    // this.toggleItems()

    if (this.quantity > 0) {
      this.cartTarget.classList.remove("hidden")
    } else {
      this.cartTarget.classList.add("hidden")
    }
  }

  disconnect() {
    this.emptyCart()
  }

  toggleItem(itemContainer = nil, initialSetup = false) {
    let hiddenCheckbox

    if (itemContainer instanceof HTMLElement) {
      hiddenCheckbox = itemContainer.querySelector("[data-simple-cart-target='hiddenCheckbox']")
    } else {
      let itemContainer = event.target
      hiddenCheckbox = itemContainer.querySelector('[data-simple-cart-target="hiddenCheckbox"]')
    }

    if (hiddenCheckbox.checked) {
      if (initialSetup) {
        this.addToCart(itemContainer)
      } else {
        this.removeFromCart(itemContainer)
      }
    } else {
      if (initialSetup) {
        return
      } else {
        this.addToCart(itemContainer)
      }
    }
  }

  addToCart(itemContainer) {
    let itemId, itemName, itemAmount, hiddenCheckbox,
        addText, removeText, toggleButton, paymentType, otherOption

    const itemCont = itemContainer instanceof HTMLElement ? itemContainer : event.target

    itemId = itemCont.getAttribute("data-simple-cart-item-id")
    itemName = itemCont.getAttribute("data-simple-cart-item-name")
    itemAmount = itemCont.getAttribute("data-simple-cart-item-amount")
    hiddenCheckbox = itemCont.querySelector("[data-simple-cart-target='hiddenCheckbox']")
    toggleButton = itemCont.querySelector("[data-simple-cart-target='toggleItemBtn']")
    removeText = itemCont.getAttribute("data-simple-cart-remove-text")
    paymentType = itemCont.getAttribute("data-simple-cart-payment-type")
    otherOption = document.querySelector(`[data-simple-cart-item-id="${itemId}"][data-simple-cart-payment-type]:not([data-simple-cart-payment-type="${paymentType}"])`)

    if (otherOption) { this.removeFromCart(otherOption) }

    hiddenCheckbox.checked = true
    this.cartTotal = this.cartTotal.add(itemAmount, true)
    this.quantity += 1
    this.totalTarget.innerHTML = this.cartTotal.toText()

    if (this.hasQuantityTarget) { this.quantityTarget.innerHTML = this.quantity }

    if (this.hasItemNameTarget) {
      this.itemNameTarget.innerHTML = `${ this.itemNameTarget.getAttribute("data-simple-cart-item-name") }${ this.quantity === 1 ? "" : "s" }`
    }

    toggleButton.innerHTML = removeText || "Remove"

    this.cartTarget.classList.remove("hidden")
  }

  removeFromCart(itemContainer) {
    const itemCont = itemContainer instanceof HTMLElement ? itemContainer : event.target

    const itemId = itemCont.getAttribute("data-simple-cart-item-id")
    const itemName = itemCont.getAttribute("data-simple-cart-item-name")
    const itemAmount = itemCont.getAttribute("data-simple-cart-item-amount")
    const hiddenCheckbox = itemCont.querySelector("[data-simple-cart-target='hiddenCheckbox']")
    const toggleButton = itemCont.querySelector("[data-simple-cart-target='toggleItemBtn']")
    const addText = itemCont.dataset["simpleCartAddText"]

    if (!hiddenCheckbox.checked) { return }

    hiddenCheckbox.checked = false
    this.cartTotal = this.cartTotal.subtract(itemAmount, true)
    this.quantity -= 1
    this.totalTarget.innerHTML = this.cartTotal.toText()

    if (this.hasQuantityTarget) { this.quantityTarget.innerHTML = this.quantity }

    if (this.hasItemNameTarget) {
      this.itemNameTarget.innerHTML = `${ this.itemNameTarget.getAttribute("data-simple-cart-item-name") }${ this.quantity === 1 ? "" : "s" }`
    }

    if (this.quantity == 0) { this.cartTarget.classList.add("hidden") }

    toggleButton.innerHTML = addText || "Add"
  }

  toggleItems() {
    this.itemContainerTargets.forEach(itemContainer => {
      this.toggleItem(itemContainer, true)
    })
  }

  emptyCart() {
    this.cartTotal = new Money({ amount: 0 })
    this.quantity = 0
  }
}
