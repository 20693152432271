import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.removeFromUI()
    this.preventFileDrop()
  }

  removeFromUI() {
    let fileTools = this.element.querySelector("[data-trix-button-group='file-tools']")

    if (fileTools) {
      fileTools.remove()
    }
  }

  preventFileDrop() {
    this.editor.addEventListener("trix-file-accept", (e) => {
      e.preventDefault()
    })
  }

  get editor() {
    return this.element.getElementsByTagName("trix-editor")[0]
  }
}
