import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "output", "loadingIndicator", "searchParam"]
  static values = { url: String, delay: Number }

  connect() {
    this.delayValue ||= 2000

    this.preview()
  }

  initDelayedPreview() {
    if (this.previewInitialized === true) return

    this.previewInitialized = true

    this.showLoadingIndicator()

    setTimeout(() => {
      this.preview()
      this.hideLoadingIndicator()
    }, this.delayValue)
  }

  preview() {
    let url = this.buildURL()
    this.outputTarget.src = url.toString()

    this.previewInitialized = false
  }

  buildURL() {
    let url = new URL(this.urlValue)
    url.searchParams.append("body", this.inputTarget.value)

    this.searchParamTargets.forEach(param => {
      const name = param.dataset.livePreviewParamName
      const val = param.value
      url.searchParams.append(name, val)
    })

    return url
  }

  showLoadingIndicator() {
    this.loadingIndicatorTarget.classList.remove("hidden")
  }

  hideLoadingIndicator() {
    this.loadingIndicatorTarget.classList.add("hidden")
  }
}
