import { Controller } from "@hotwired/stimulus"
import Money from 'src/dinero-custom'

export default class extends Controller {
  static targets = [
                     "numAwards",
                     "numAllocations",
                     "totalAwarded",
                     "balance",
                     "elementToObserve",
                     "form",
                     "hiddenForm",
                     "hiddenFieldsContainer",
                     "formSubmitBtn",
                     "totalsContainer"
                   ]

  static values = {
                    page: String,
                    hasStudentCap: Boolean
                  }

  connect() {
    this.tuitionExceeded = []
    this.fundBalanceExceeded = []
    this.studentCapExceeded = []

    this.disableFormSubmitBtn()
    this.setInitialTotals()

    if (this.hasElementToObserveTarget) {
      this.setDomChangeObserver()
    } else {
      this.performDomChangeActions()
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  setInitialTotals() {
    this.totals = {}

    if (this.hasNumAllocationsTarget) {
      this.totals.numAllocations = parseInt(this.numAllocationsTarget.dataset.numAllocations)
    }

    if (this.hasNumAwardsTarget) {
      this.totals.numAwards = parseInt(this.numAwardsTarget.dataset.numAwards)
    }

    if (this.hasTotalAwardedTarget) {
      this.totals.totalAwarded = new Money({ amount: this.totalAwardedTarget.dataset.totalAwarded || 0 })
    }

    if (this.hasBalanceTarget) {
      this.totals.balance = new Money({ amount: this.balanceTarget.dataset.balance || 0 })
    }
  }

  performDomChangeActions() {
    this.maintainChanges()
    this.updateAmountChangeTracker()
    this.populateAwards()
  }

  setDomChangeObserver() {
    this.observer = new MutationObserver(domChanges => {
      this.performDomChangeActions()
    })

    this.observer.observe(this.elementToObserveTarget, {
      childList: true,
      subtree: true
    })
  }

  updateAmountChangeTracker() {
    this.amountChangeTracker ||= {}

    document.querySelectorAll("[data-awards-target='awardAmountField']").forEach(el => {
      const studentId = el.dataset.applicationStudentId
      const initialAmount = new Money({ amount: el.dataset.initialAwardAmount || 0 })
      const newAmount = new Money({ amount: el.value || 0 })
      const initialTotalAwardedAmount = new Money({ amount: el.dataset.initialTotalAwardedAmount || 0 })
      const tuition = new Money({ amount: el.dataset.studentTuition || 0 })
      const totalFamilyAwards = new Money({ amount: el.dataset.totalFamilyAwards || 0 })
      const studentCap = el.dataset.studentCapAmount
      let newTotalAwardedAmount = 0

      if (initialAmount.amount < newAmount.amount) {
        newTotalAwardedAmount = initialTotalAwardedAmount.add(newAmount.subtract(initialAmount.amount, true).amount, true)
      } else if (initialAmount.amount > newAmount.amount) {
        newTotalAwardedAmount = initialTotalAwardedAmount.subtract(initialAmount.subtract(newAmount.amount, true).amount, true)
      } else {
        newTotalAwardedAmount = initialTotalAwardedAmount
      }

      this.amountChangeTracker[studentId] = {
                                              initialAmount: initialAmount,
                                              newAmount: newAmount,
                                              initialTotalAwardedAmount: initialTotalAwardedAmount,
                                              newTotalAwardedAmount: newTotalAwardedAmount,
                                              tuition: tuition,
                                              totalFamilyAwards: totalFamilyAwards
                                            }

      const studentRow = el.closest(`[data-awards-target="studentRow"]`)
      const tuitionErrorContainer = studentRow.querySelector('[data-awards-target="tuitionExceeded"]')
      const studentCapErrorContainer = studentRow.querySelector('[data-awards-target="studentCapExceeded"]')
      const familyCapErrorContainer = studentRow.querySelector('[data-awards-target="familyCapExceeded"]')

      if (tuitionErrorContainer) {

        if (newTotalAwardedAmount.amount > tuition.amount && tuition.amount !== 0) {
          if (this.tuitionExceeded.indexOf(studentId) === -1) {
            this.tuitionExceeded.push(studentId)
          }

          tuitionErrorContainer.classList.remove("hidden")
        } else {
          if (this.tuitionExceeded.indexOf(studentId) !== -1) {
            this.tuitionExceeded.splice(this.tuitionExceeded.indexOf(studentId), 1)
          }

          tuitionErrorContainer.classList.add("hidden")
        }
      }

      if (tuition > 0 && this.hasStudentCapValue && studentCap && studentCapErrorContainer) {
        if (newAmount > studentCap) {
          if (this.studentCapExceeded.indexOf(studentId) === -1) {
            this.studentCapExceeded.push(studentId)
          }

          studentCapErrorContainer.classList.remove("hidden")
        } else {
          if (this.studentCapExceeded.indexOf(studentId) !== -1) {
            this.studentCapExceeded.splice(this.studentCapExceeded.indexOf(studentId), 1)
          }

          studentCapErrorContainer.classList.add("hidden")
        }
      }
    })
  }

  updateFundBalanceTracker() {
    this.fundBalanceTracker ||= {}

    document.querySelectorAll("[data-awards-target='awardAmountField']").forEach(el => {
      const fundId = el.dataset.fundId
      const initialAmount = new Money({ amount: el.dataset.initialAwardAmount || 0 })
      const newAmount = new Money({ amount: el.value || 0 })
      const initialFundBalance = el.dataset.initialFundBalance
      let newFundBalance = 0

      if (initialAmount < newAmount) {
        newFundBalance = initialFundBalance - (newAmount - initialAmount)
      } else if (initialAmount > newAmount) {
        newFundBalance = initialFundBalance + (initialAmount - newAmount)
      } else {
        newFundBalance = initialFundBalance
      }

      this.fundBalanceTracker[fundId] = {
                                            initialAmount: initialAmount,
                                            newAmount: newAmount,
                                            initialFundBalance: initialFundBalance,
                                            newFundBalance: newFundBalance
                                         }

      const studentRow = el.closest(`[data-awards-target="studentRow"]`)
      const fundBalanceErrorContainer = studentRow.querySelector('[data-awards-target="fundBalanceExceeded"]')

      if (newFundBalance < 0) {
        if (this.fundBalanceExceeded.indexOf(fundId) === -1) {
          this.fundBalanceExceeded.push(fundId)
        }

        fundBalanceErrorContainer.classList.remove("hidden")
      } else {
        if (this.fundBalanceExceeded.indexOf(fundId) !== -1) {
          this.fundBalanceExceeded.splice(this.fundBalanceExceeded.indexOf(fundId), 1)
        }

        fundBalanceErrorContainer.classList.add("hidden")
      }
    })
  }

  populateAwards() {
    if (this.pageValue == "studentShow") {
      this.populateFromStudentPage()
    } else if (this.pageValue == "fundAllocations") {
      this.populateFromFundAllocationsPage()
    } else if (this.pageValue == "fundAwards") {
      this.populateFromFundPage()
    }
  }

  populateFromFundPage() {
    this.awards ||= {}
    this.tempAwardsObj = {}

    document.querySelectorAll("[data-awards-target='awardAmountField']").forEach(el => {
      const studentId = el.dataset.applicationStudentId
      const studentName = el.dataset.studentName
      const applicationId = el.dataset.applicationId
      const awardId = el.dataset.awardId || ""
      const awardAmount = new Money({ amount: el.value || 0 })
      const awardedByUserId = el.dataset.awardedByUser
      const studentTuition = new Money({ amount: el.dataset.studentTuition || 0 })
      const totalFamilyAwards = new Money({ amount: el.dataset.totalFamilyAwards || 0 })

      this.tempAwardsObj[studentId] = {
                                        application_student_id: studentId,
                                        application_id: applicationId,
                                        id: awardId || "",
                                        amount: awardAmount,
                                        awarded_by_user: awardedByUserId,
                                        studentTuition: studentTuition,
                                        totalFamilyAwards: totalFamilyAwards
                                      }
    })

    document.querySelectorAll("[data-awards-target='awardStatusField']").forEach(el => {
      const studentId = el.dataset.applicationStudentId
      const awardStatus = el.value

      this.tempAwardsObj[studentId].status = awardStatus
    })

    this.awards = {...this.awards, ...this.tempAwardsObj}
  }

  populateFromStudentPage() {
    this.awards ||= {}
    this.tempAwardsObj = {}

    document.querySelectorAll("[data-awards-target='awardAmountField']").forEach(el => {
      const awardId = el.dataset.awardId
      const fundId = el.dataset.fundId
      const fundName = el.dataset.fundName
      const awardedByUserId = el.dataset.awardedByUser
      const awardAmount = new Money({ amount: el.value || 0 })
      const studentTuition = el.dataset.studentTuitionCents

      this.tempAwardsObj[fundId] = {
                                      fund_id: fundId,
                                      fund_name: fundName,
                                      id: awardId || "",
                                      amount: awardAmount,
                                      awarded_by_user: awardedByUserId,
                                      studentTuition: studentTuition
                                    }
    })

    document.querySelectorAll("[data-awards-target='awardStatusField']").forEach(el => {
      const fundId = el.dataset.fundId
      const awardStatus = el.value

      this.tempAwardsObj[fundId].status = awardStatus
    })

    this.awards = {...this.awards, ...this.tempAwardsObj}
  }

  populateFromFundAllocationsPage() {
    this.awards ||= {}
    this.tempAwardsObj = {}

    document.querySelectorAll("[data-awards-target='awardAmountField']").forEach(el => {
      const schoolId = el.dataset.schoolId
      const awardAmount = new Money({ amount: el.value || 0 })
      const awardId = el.dataset.awardId

      this.tempAwardsObj[schoolId] = {
                                      id: awardId || "",
                                      school_id: schoolId,
                                      amount: awardAmount
                                    }
    })

    this.awards = {...this.awards, ...this.tempAwardsObj}
  }

  updateHiddenFields(e) {
    let value

    if (["awardAmountField", "totalReturnedField"].includes(e.target.dataset.awardsTarget)) {
      value = e.target.value || "0"
      if (value !== "0") e.target.value = value.replace(/[^0-9.]/g, '')
    } else {
      value = e.target.value
    }

    if (this.pageValue == "studentShow") {
      const fundId = e.target.dataset.fundId
      this.oldAwardAmount = this.awards[`${fundId}`]["amount"]
    } else if (this.pageValue == "fundAllocations") {
      const schoolId = e.target.dataset.schoolId
      this.oldAwardAmount = this.awards[`${schoolId}`]["amount"]
    } else {
      const studentId = e.target.dataset.applicationStudentId
      this.oldAwardAmount = this.awards[`${studentId}`]["amount"]
    }

    this.populateAwards()
    this.disableFormSubmitBtn()

    if (this.pageValue !== "studentShow") {
      this.updateAmountChangeTracker()
    } else {
      this.updateFundBalanceTracker()
    }

    const changedField = e.target
    const fieldNameKey = e.target.dataset.fieldNameKey

    let key
    let baseName
    let fieldName

    if (this.pageValue == "studentShow") {
      key = e.target.dataset.fundId
      baseName = "application_student[awards_attributes]"
      fieldName = `${baseName}[${key}][${fieldNameKey}]`
    } else if (this.pageValue == "fundAwards") {
      key = e.target.dataset.applicationStudentId
      baseName = "fund[awards_attributes]"
      fieldName = `${baseName}[${key}][${fieldNameKey}]`
    } else if (this.pageValue == "fundAllocations") {
      key = e.target.dataset.schoolId
      baseName = "fund[fund_allocations_attributes]"
      fieldName = `${baseName}[${key}][${fieldNameKey}]`
    }

    this.createHiddenField(e, value, baseName, fieldName)

    if (fieldNameKey === "amount") {
      this.updateTotals(e)
    }

    this.toggleFormSubmitBtn()
  }

  createHiddenField(e, value, baseName, fieldName) {
    const updatingReturns = e.target.dataset.awardsTarget == "totalReturnedField"
    const existingHiddenField = this.hiddenFieldsContainerTarget.querySelector(`[name="${fieldName}"]`)
    const newValue = value

    if (existingHiddenField) {
      existingHiddenField.value = newValue
      return
    }

    const studentRow = e.target.closest("[data-awards-target='studentRow']")

    let data
    let objId

    if (this.pageValue == "fundAllocations") {
      objId = e.target.dataset.schoolId

      data = {
        id: e.target.dataset.awardId || "",
        school_id: e.target.dataset.schoolId,
        amount: newValue
      }
    } else {
      objId = studentRow.querySelector("[data-awards-target='applicationStudentId']").value

      data = {
        application_student_id: objId,
        id: studentRow.querySelector("[data-awards-target='id']").value || "",
        amount: studentRow.querySelector("[data-awards-target='awardAmountField']").value,
        awarded_by_user: studentRow.querySelector("[data-awards-target='awardedByUser']").value,
        status: studentRow.querySelector("[data-awards-target='awardStatusField']").value
      }

      if (this.pageValue == "studentShow") {
        const fundId = studentRow.querySelector("[data-awards-target='fundId']").value
        data["fund_id"] = fundId
        objId = fundId
      } else if (updatingReturns) {
        data["total_returned"] = studentRow.querySelector("[data-awards-target='totalReturnedField']").value
      }
    }


    for (const [key, value] of Object.entries(data)) {
      const hiddenField = document.createElement("input")
      hiddenField.setAttribute("type", "hidden")
      hiddenField.setAttribute("name", `${baseName}[${objId}][${key}]`)
      hiddenField.setAttribute("value", value)

      this.hiddenFieldsContainerTarget.appendChild(hiddenField)
    }
  }

  maintainChanges() {
    document.querySelectorAll("[data-awards-target='awardAmountField']").forEach(el => {
      const key = this.pageValue == "studentShow" ? el.dataset.fundId : el.dataset.applicationStudentId

      if (this.awards && this.awards[key]) {
        const storedAmount = this.awards[key].amount

        el.value = storedAmount.toInput()
      }
    })

    document.querySelectorAll("[data-awards-target='awardStatusField']").forEach(el => {
      const key = this.pageValue == "studentShow" ? el.dataset.fundId : el.dataset.applicationStudentId

      if (this.awards && this.awards[key]) {
        const storedStatus = this.awards[key].status

        el.value = storedStatus
      }
    })
  }

  updateTotals(e) {
    const newValue = new Money({ amount: e.target.value || 0 })
    const awardFromAllocation = e.target.dataset.allocationId

    if (this.oldAwardAmount.amount == 0 && newValue.amount != 0) {
      this.totals["numAwards"] += 1

      if (this.hasNumAllocationsTarget) {
        this.totals["numAllocations"] += 1
      }
    } else if (this.oldAwardAmount.amount != 0 && newValue.amount == 0) {
      this.totals["numAwards"] -= 1

      if (this.hasNumAllocationsTarget) {
        this.totals["numAllocations"] -= 1
      }
    }

    if (this.oldAwardAmount.subtract(newValue.amount, true).amount > 0) {
      this.totals["totalAwarded"] = this.totals["totalAwarded"].subtract(this.oldAwardAmount.subtract(newValue.amount, true).amount, true)

      if (this.hasBalanceTarget) {
        if(!awardFromAllocation) this.totals["balance"] = this.totals["balance"].add(this.oldAwardAmount.subtract(newValue.amount, true).amount, true)
      }
    } else {
      this.totals["totalAwarded"] = this.totals["totalAwarded"].add(newValue.subtract(this.oldAwardAmount.amount, true).amount, true)

      if (this.hasBalanceTarget) {
        if(!awardFromAllocation) this.totals["balance"] = this.totals["balance"].subtract(newValue.subtract(this.oldAwardAmount.amount, true).amount, true)
      }
    }

    if(this.hasNumAllocationsTarget) {
      this.numAllocationsTarget.innerHTML = this.totals["numAllocations"]
    }

    if (this.hasNumAwardsTarget) {
      this.numAwardsTarget.innerHTML = this.totals["numAwards"]
    }

    if (this.hasTotalAwardedTarget) {
      this.totalAwardedTarget.innerHTML = this.totals["totalAwarded"].toText()
    }

    if (this.hasBalanceTarget && !awardFromAllocation) {
      this.balanceTarget.innerHTML = this.totals["balance"].toText()
    }

    if (this.hasBalanceTarget && !awardFromAllocation) {
      if (this.totals["balance"].amount < 0) {
        this.balanceTarget.classList.add("fund-awards__balance-amount--exceeded")
      } else {
        this.balanceTarget.classList.remove("fund-awards__balance-amount--exceeded")
      }
    }
  }

  toggleFormSubmitBtn() {
    let enable = true

    if (this.hasBalanceTarget) {
      if (this.totals["balance"].amount < 0) {
        enable = false
      }
    }

    if (this.tuitionExceeded.length) {
      enable = false
    }

    if (this.fundBalanceExceeded.length) {
      enable = false
    }

    if (this.studentCapExceeded.length) {
      enable = false
    }

    enable ? this.enableFormSubmitBtn() : this.disableFormSubmitBtn()
  }

  disableFormSubmitBtn() {
    this.formSubmitBtnTarget.classList.add("button--disabled")
  }

  enableFormSubmitBtn() {
    this.formSubmitBtnTarget.classList.remove("button--disabled")
  }
}
