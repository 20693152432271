import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "placeholder", "hiddenPositionField"]
  static values = { updateType: String }

  placeholderClass = "draggable__placeholder"
  draggingClass = "draggable__dragging"

  connect(){
    this.setInputEventListeners()
    this.updateTypeValue ||= "automatic"
  }

  dragstart(e) {
    this.previousPositions = this.getCurrentPositions()

    let placeholder = e.target.cloneNode(true)
    placeholder.classList.add(this.placeholderClass)
    placeholder.style.width = `${e.target.offsetWidth}px`
    placeholder.setAttribute("data-draggable-target", "placeholder")

    // Must place element outside of form to prevent selectable fields from clearing
    document.body.appendChild(placeholder)
    // MARKTODO: More abstract offset
    e.dataTransfer.setDragImage(placeholder, 35, 55)

    window.requestAnimationFrame(() => {
      e.target.classList.add(this.draggingClass)
      e.target.setAttribute('data-dragging', true)
    })
  }

  dragover(e) {
    e.preventDefault()

    let afterElement = this.getDragAfterElement(e.clientY)
    let draggedItem = document.querySelector("[data-dragging='true']")

    if (afterElement == null){
      this.element.appendChild(draggedItem)
    } else {
      this.element.insertBefore(draggedItem, afterElement)
    }
  }

  dragend(e) {
    window.requestAnimationFrame(() => {
      const droppedElement = e.target

      droppedElement.classList.remove(this.draggingClass)
      droppedElement.removeAttribute('data-dragging')
      this.activateTurboLink(droppedElement)
    })

    document.querySelectorAll("[data-draggable-target='placeholder']").forEach((el, i) => {
      el.remove()
    })

    if (this.getCurrentPositions() !== this.previousPositions) {
      if (this.updateTypeValue === "automatic") {
        this.updateDatabase()
      } else {
        this.updateHiddenFields()
      }
    }
  }

  activateTurboLink(droppedElement) {
    if (droppedElement.dataset.controller?.includes("turbo-link")) {
      const styleToggle = droppedElement.querySelector("[data-turbo-link-target='styleToggle']")

      if (styleToggle) {
        styleToggle.parentNode.querySelector("a")?.click()
      }
    }
  }

  updateHiddenFields() {
    this.hiddenPositionFieldTargets.forEach((field, index) => {
      field.value = index
    })

    const event = new Event("change")
    this.element.closest("form").dispatchEvent(event)
  }

  getDragAfterElement(y) {
    return this.itemTargets.reduce((closest, child) => {
      let box = child.getBoundingClientRect()
      let offset = y - box.top - box.height / 2

      if (offset < 0 && offset > closest.offset) {
        return { offset: offset, element: child }
      } else {
        return closest
      }
    }, { offset: Number.NEGATIVE_INFINITY }).element
  }

  getCurrentPositions(stringify = true) {
    let positions = {}

    this.itemTargets.forEach((el, i) => {
      let recordId = el.dataset.draggableId
      let position = i + 1

      positions[recordId] = position
    })

    if (stringify) {
      return JSON.stringify(positions)
    } else {
      return positions
    }
  }

  updateDatabase() {
    const token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const newPositions = JSON.parse(this.getCurrentPositions())
    const body = { positions: newPositions }

    fetch(this.url, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(body)
    })
  }

  setInputEventListeners() {
    let inputs = this.element.querySelectorAll("input")

    inputs.forEach((el, i) => {
      inputs[i].addEventListener("mousedown", (e) => {
        e.stopPropagation()
        e.target.closest("[draggable]").setAttribute("draggable", false)
      })

      inputs[i].addEventListener("mouseup", (e) => {
        e.stopPropagation()
        e.target.closest("[draggable]").setAttribute("draggable", true)
      })
    })
  }

  get previousPositions() {
    return this.data.get("previousPositions")
  }

  set previousPositions(positions) {
    this.data.set("previousPositions", positions)
  }

  get url() {
    return this.data.get("url")
  }
}
