import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['applicantTaxFiledField', 'applicantDnfField', 'coapplicantTaxFiledField', 'coapplicantDnfField', 'dependentsIncomeField', 'dependentsTaxFiledField', 'changeOfIncomeField']

  static classes = [ "hidden" ]

  toggleFilingStatus (event) {
    if (event.target.value == "" || event.target.value == "none_filed") {

      this.hideFields(this.applicantTaxFiledFieldTargets)

      let w2IncomeChecked = document.querySelector('input[name="application[income_attributes][w2_income]"]:checked')
      if (w2IncomeChecked && w2IncomeChecked.value == "true" && event.target.value == "none_filed") {
        this.showFields(this.applicantDnfFieldTargets)
      } else {
        this.hideFields(this.applicantDnfFieldTargets)
      }
    } else {
      this.showFields(this.applicantTaxFiledFieldTargets)
      this.hideFields(this.applicantDnfFieldTargets)
    }

    this.controlCoapplicantFilingSelect(event.target.value)
  }

  toggleCoapplicantFilingStatus (event) {
    if (event.target.value == "" || event.target.value == "none_filed") {
      this.hideFields(this.coapplicantTaxFiledFieldTargets)

      let w2IncomeChecked = document.querySelector('input[name="application[income_attributes][coapplicant_w2_income]"]:checked')
      if (w2IncomeChecked && w2IncomeChecked.value == "true" && event.target.value == "none_filed") {
        this.showFields(this.coapplicantDnfFieldTargets)
      } else {
        this.hideFields(this.coapplicantDnfFieldTargets)
      }

    } else {
      this.showFields(this.coapplicantTaxFiledFieldTargets)
      this.hideFields(this.coapplicantDnfFieldTargets)
    }
  }

  toggleApplicantW2Income (event) {
    let filingStatus = document.querySelector('#application_income_attributes_federal_filing_status').value

    if (event.target.value == "true" && filingStatus == 'none_filed') {
      this.showFields(this.applicantDnfFieldTargets)
    } else {
      this.hideFields(this.applicantDnfFieldTargets)
    }
  }

  togglecoapplicantW2Income (event) {
    let filingStatus = document.querySelector('#application_income_attributes_coapplicant_federal_filing_status').value

    if (event.target.value == "true" && filingStatus == 'none_filed') {
      this.showFields(this.coapplicantDnfFieldTargets)
    } else {
      this.hideFields(this.coapplicantDnfFieldTargets)
    }
  }

  toggleDependentsIncome (event) {
    if (event.target.value == "true") {
      this.showFields(this.dependentsIncomeFieldTargets)
    } else {
      this.hideFields(this.dependentsIncomeFieldTargets)
      this.hideFields(this.dependentsTaxFiledFieldTargets)
    }
  }

  toggleDependentsTaxFiledFields (event) {
    if (event.target.value == "true") {
      this.showFields(this.dependentsTaxFiledFieldTargets)
    } else {
      this.hideFields(this.dependentsTaxFiledFieldTargets)
    }
  }

  showFields (elements) {
    elements.forEach(input => {
      let parentFieldWrapper = input.closest(".input-wrapper")
      parentFieldWrapper.classList.remove(this.hiddenClass)
    })
  }

  hideFields (elements) {
    elements.forEach(input => {
      let parentFieldWrapper = input.closest(".input-wrapper")
      parentFieldWrapper.classList.add(this.hiddenClass)

      if (input.type == "radio") {
        input.checked = false
      } else {
        input.value = "0.00"
      }
    })
  }

  controlCoapplicantFilingSelect (filingStatus) {
    let coappliantFilingSelect = document.querySelector('#application_income_attributes_coapplicant_federal_filing_status')
    if (coappliantFilingSelect) {
      if (filingStatus == "married_jointly") {
         coappliantFilingSelect.closest(".input-wrapper").classList.add(this.hiddenClass)
         coappliantFilingSelect.value = ""
         this.hideFields(this.coapplicantDnfFieldTargets)
         this.hideFields(this.coapplicantTaxFiledFieldTargets)

      } else {
         coappliantFilingSelect.closest(".input-wrapper").classList.remove(this.hiddenClass)
      }
    }
  }

  toggleChangeOfIncomeField (event) {
    if (event.target.value == "true") {
      this.showFields(this.changeOfIncomeFieldTargets)
    } else {
      this.hideFields(this.changeOfIncomeFieldTargets)
      var checkboxes = document.getElementsByName("application[income_attributes][change_of_income_reasons][]")
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
    }
  }

}
