import { Controller } from "@hotwired/stimulus"
import Money from 'src/dinero-custom'

export default class extends Controller {
  static targets = ['percentageRate', 'primaryAmount', 'secondaryAmount', 'rate']
  static values = { totalAmountCents: Number }

  initialize () {
  }

  calculateRangeValues() {
    let rate = this.percentageRateTarget.value;
    let totalAmount = new Money({ amount: this.totalAmountCentsValue, cents: true });

    let primaryAmount = totalAmount.percentage(rate);
    let secondaryAmount = totalAmount.subtract(primaryAmount.toInput());

    this.primaryAmountTarget.innerText = primaryAmount.toText();
    this.secondaryAmountTarget.innerText = secondaryAmount.toText();
    this.rateTarget.innerText = this.percentageRateTarget.value + '%'
  }

  rateToDecimal(rate) {
    return parseFloat(rate/100)
  }
}
