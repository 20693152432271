import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    column: String
  }

  toggle(e) {
    let token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
    let params = { column: this.columnValue };

    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
        "Accept": "text/javascript"
      },
      body: JSON.stringify(params)
    })
  }
}
