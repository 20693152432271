import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template", "item", "addItem", "insertion", "hiddenIdField"]
  static values = { insertFrom: String, hiddenIdFieldName: String }

  hiddenClass = 'hidden'

  addAssociation(e) {
    e.preventDefault()

    let template = this.templateTarget.innerHTML.replace(/CHILD_INDEX_PLACEHOLDER/g, Math.floor(Math.random() * 1000000))

    if (this.insertFromValue && this.insertFromValue == "top") {
      this.addItemTarget.insertAdjacentHTML("afterEnd", template)
    } else if (this.insertFromValue && this.insertFromValue == "bottom") {
      this.insertionTarget.insertAdjacentHTML("beforeend", template)
    } else {
      this.insertionTarget.insertAdjacentHTML("beforebegin", template)
    }
  }

  removeAssociation(e) {
    e.preventDefault()

    let item = e.target.closest("[data-nested-form-target*='item']")
    item.remove()

    const event = new Event("change")
    this.element.closest("form").dispatchEvent(event)
  }

  softRemoveAssociation(e) {
    e.preventDefault()

    let item = e.target.closest("[data-nested-form-target*='item']")
    let destroyField = item.querySelector("input[name*='_destroy'")

    item.classList.add("hidden")
    destroyField.value = 1
  }

  removeAssociationFromCheckbox(e) {
    let item = e.target.closest("[data-nested-form-target*='item']")
    let destroyField = item.querySelector("input[name*='_destroy'")

    if (destroyField) {
      if (e.target.checked) {
        destroyField.value = 0
      } else {
        destroyField.value = 1
      }
    }
  }

  removeAssociationId(e) {
    const idToRemove = e.target.dataset.nestedFormValue

    this.hiddenIdFieldTargets.forEach(fld => {
      if (fld.value == idToRemove) fld.remove()
    })

    if (this.hiddenIdFieldTargets.length === 0) {
      this.insertHiddenIdField("")
    }
  }

  addAssociationId(e) {
    const idToAdd = e.target.dataset.nestedFormValue
    let fieldExists = false

    this.hiddenIdFieldTargets.forEach(fld => {
      if (fld.value == idToAdd) fieldExists = true
    })

    if (!fieldExists) {
      this.insertHiddenIdField(idToAdd)
    }
  }

  insertHiddenIdField(val) {
    this.element.insertAdjacentHTML("beforeend", `<input type='hidden' name='${this.hiddenIdFieldNameValue}' value='${val}' data-nested-form-target='hiddenIdField'></input>`)
  }
}
