import { Controller } from "@hotwired/stimulus"

const shownSelectionMax = 3

export default class extends Controller {
  static targets = ["multiselect", "label", "labelSelections"]

  connect() {
    this.update()
  }

  update() {
    this.multiselectTargets.forEach((multiselect) => {
      this.updateMultiselect(multiselect)
    })
  }

  updateMultiselect(multiselect) {
    const selections = this.currentSelections(multiselect)
    const label = multiselect.querySelector("[data-multiselect-target='label']")
    const labelSelections = multiselect.querySelector("[data-multiselect-target='labelSelections']")
    let text = label.textContent.split(" | ")[0]

    if (selections.length > 0) {
      label.textContent = `${text} | `
      labelSelections.textContent = this.currentSelectionsList(selections)
    } else {
      label.textContent = text
      labelSelections.textContent = ""
    }
  }

  currentSelections(multiselect) {
    let selectionArray = []

    multiselect.querySelectorAll("[type='checkbox']").forEach((checkbox) => {
      if (!checkbox.checked) {
        return
      }

      selectionArray.push(checkbox.nextElementSibling.textContent)
    })

    return selectionArray
  }

  currentSelectionsList(selections) {
    if (selections.length > shownSelectionMax) {
      return `${selections.slice(0, shownSelectionMax - 1).join(", ")}, and ${selections.length - shownSelectionMax} more`
    } else {
      return selections.join(", ")
    }
  }
}
