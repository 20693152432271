import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  hiddenClass = 'hidden';

  toggle(event) {
    let button = event.currentTarget
    let containers = this.expandableContainers(button)
    
    this.expandableIcons(button).forEach((icon) => {
      icon.classList.toggle(this.hiddenClass)
    })

    containers.forEach((container) => {
      container.classList.toggle(this.hiddenClass)

      if (container.classList.contains(this.hiddenClass)) {
        this.hideAdditionalContainers(container)
      }
    })
  }

  hideAdditionalContainers(button) {
    let containers = this.expandableContainers(button)

    if (containers.length === 0) {
      return false
    }

    let icons = this.expandableIcons(button)
    icons[0].classList.remove(this.hiddenClass)
    icons[1].classList.add(this.hiddenClass)

    containers.forEach((container) => {
      container.classList.add(this.hiddenClass)
      this.hideAdditionalContainers(container)
    })
  }

  expandableContainers(button) {
    return document.querySelectorAll(`[data-expandable-target='${button.dataset.target}']`)
  }

  expandableIcons(button) {
    return button.querySelectorAll('.expandable-icon')
  }
}
