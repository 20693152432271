import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = ["tooltip"]
  static values = { content: String, clickContent: String, position: String }

  connect() {
    this.positionValue ||= "top"

    this.isTouchDevice = ('ontouchstart' in document.documentElement && /mobi/i.test(navigator.userAgent))

    let actions;

    // Ignore mouse events and use touch events if mobile device
    if (this.isTouchDevice) {
      actions = "touchend->tooltip#show"
    } else {
      actions = "mouseenter->tooltip#show mouseleave->tooltip#hide"
    }

    actions = this.element.dataset.action ? actions.concat(` ${this.element.dataset.action}`) : actions
    this.element.dataset.action = actions.trimLeft()
  }

  disconnect() {
    if (this.isTouchDevice) document.removeEventListener("touchstart", this.hide)
  }

  show() {
    if (this.isTouchDevice) document.addEventListener("touchstart", this.hide)

    let contentToInsert;

    if (event.type == "click") {
      this.hide()
      contentToInsert = this.clickContentValue || this.contentValue
    } else {
      contentToInsert = this.contentValue
    }

    let tooltip = `
      <div class="tooltip tooltip-dark" id="tooltip" role="tooltip" data-tooltip-target="tooltip">
        ${contentToInsert}
        <div id="arrow" data-popper-arrow></div>
      </div>
    `
    document.body.insertAdjacentHTML("beforeend", tooltip)

    let tooltipContent = document.querySelector("[data-tooltip-target='tooltip']")

    createPopper(this.element, tooltipContent, {
      placement: this.positionValue
    })
  }

  hide() {
    document.querySelectorAll("[data-tooltip-target='tooltip']").forEach((ele) => ele.remove())
    if (this.isTouchDevice) document.removeEventListener("touchstart", this.hide)
  }
}
