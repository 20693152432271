import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  streamContent(e) {
    const selected = e.target.value
    const queryStringDelimiter = (this.urlValue.indexOf('?') == -1 ? '?' : '&')
    const url = `${this.urlValue}${queryStringDelimiter}selected=${selected}`

    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        "content-Type": "application/json"
      },
      credentials: "same-origin",
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
