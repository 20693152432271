import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filter", "datePicker", "container", "label", "labelSelections"]
  static values = { url: String }

  connect() {
    this.update()
    
    this.filterTargets.forEach((target) => {
      target.addEventListener("keydown", this.onKeydown.bind(this))
    })
  }

  onKeydown(event) {
    if (event.key == "Enter") {
      this.filter()
    }
  }

  resetFilters(event) {
    const container = document.querySelector(event.currentTarget.dataset.target)

    container.querySelectorAll("[data-filters-target='filter']").forEach((target) => {
      switch (target.type) {
      case "hidden":
        break;
      case "select":
        this.selectDefault(target);
        break;
      case "checkbox":
        this.checkboxDefault(target);
        break;
      default:
        this.filterDefault(target);
      }
    })

    this.filter()
  }

  filter() {
    this.hideDropdowns()
    let url = null

    if (this.urlValue) {
      url = new URL(this.urlValue)
    } else {
      url = new URL(window.location.href)
    }

    const token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    url.search = this.params.toString()

    fetch(url, {
      headers: {
        "X-CSRF-Token": token,
        "Accept": "text/vnd.turbo-stream.html",
      },
      method: "POST",
    }).then(response => {
      if (response.ok) {
        return response.text()
      } else {
        return Promise.reject(`Error: ${response.status}`)
      }
    }).then(html => Turbo.renderStreamMessage(html))

    this.update()
  }

  update() {
    this.containerTargets.forEach((container) => {
      let filter = container.querySelector("[data-filters-target='filter']")

      if (filter instanceof HTMLSelectElement) {
        if (this.isDatePicker(filter))
          this.updateDatePicker(container, filter)
        else {
          this.updateFilter(container, filter)
        }
      }
    })
  }

  updateFilter(container, filter) {
    const label = container.querySelector("[data-filters-target='label']")
    const labelSelections = container.querySelector("[data-filters-target='labelSelections']")
    let text = label.textContent.split(" | ")[0]
    
    if (filter.selectedIndex != 0) {
      label.textContent = `${text} | `
      labelSelections.textContent = filter.options[filter.selectedIndex].text
    } else {
      label.textContent = text
      labelSelections.textContent = ""
    }
  }

  updateDatePicker(container, filter) {
    const label = container.querySelector("[data-filters-target='label']")
    const startDate = container.querySelector("[data-start-datepicker]")
    const endDate = container.querySelector("[data-end-datepicker]")

    label.textContent = `${startDate.value.substring(4)} - ${endDate.value.substring(4)}`
  }

  hideDropdowns() {
    this.dropdowns.forEach((dropdown) => {
      dropdown.classList.remove('show')
    })
  }

  selectDefault(target) {
    if (this.hasDefaultValue(target)) {
      target.value = target.dataset.defaultValue
    } else {
      target.selectedIndex = 0
    }
  }

  checkboxDefault(target) {
    if (this.hasDefaultValue(target)) {
      target.checked = target.dataset.defaultValue
    } else {
      target.checked = false
    }
  }

  filterDefault(target) {
    if (this.hasDefaultValue(target)) {
      target.value = target.dataset.defaultValue
    } else {
      target.value = ""
    }
  }

  isDatePicker(target) {
    return typeof target.dataset.filtersType !== "undefined" && target.dataset.filtersType == "datePicker"
  }

  hasDefaultValue(target) {
    return typeof target.dataset.defaultValue !== "undefined"
  }

  get dropdowns() {
    return document.querySelectorAll(".dropdown-menu") 
  }

  get params() {
    let paramsArray = []

    this.filterTargets.forEach((target) => {
      if (target.type == "checkbox" && !target.checked) {
        return
      }

      paramsArray.push(`${target.name}=${target.value}`)
    })

    return new URLSearchParams(`?${paramsArray.join('&')}`)
  }
}
