import Trix from "trix"

addForegroundColorAttributes();
addBackgroundColorAttributes();

function foregroundColors() {
  return [
    "rgb(136, 118, 38)", 
    "rgb(185, 94, 6)", 
    "rgb(207, 0, 0)",
    "rgb(216, 28, 170)", 
    "rgb(144, 19, 254)", 
    "rgb(5, 98, 185)", 
    "rgb(17, 138, 15)", 
    "rgb(148, 82, 22)", 
    "rgb(102, 102, 102)",
    "rgb(255, 255, 255)"
  ];
}

function backgroundColors() {
  return [
    "rgb(250, 247, 133)", 
    "rgb(255, 240, 219)", 
    "rgb(255, 229, 229)", 
    "rgb(255, 228, 247)", 
    "rgb(242, 237, 255)", 
    "rgb(225, 239, 252)", 
    "rgb(228, 248, 226)", 
    "rgb(238, 226, 215)", 
    "rgb(242, 242, 242)",
    "rgb(255, 255, 255)"
  ];
}

function addForegroundColorAttributes() {
  Array.from(foregroundColors()).forEach((color, i) => {
    Trix.config.textAttributes[`fgColor${(i + 1)}`] = { style: { color: color }, inheritable: true, parser: e => e.style.color == color }
  })
}

function addBackgroundColorAttributes() {
  Array.from(backgroundColors()).forEach((color, i) => {
    Trix.config.textAttributes[`bgColor${(i + 1)}`] = { style: { backgroundColor: color }, inheritable: true, parser: e => e.style.backgroundColor == color }
  })
}

function trixColorButton() {
  return `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-color" data-trix-action="x-color" title="Color" tabindex="-1">Color</button>
  `;
}

function trixColorDialog() {
  return `
    <div class="trix-dialog trix-dialog--color" data-trix-dialog="x-color" data-trix-dialog-attribute="x-color">
      <div class="trix-dialog__link-fields">
        <input type="text" name="x-color" class="trix-dialog-hidden__input" data-trix-input>
        <div class="trix-button-group">
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor1" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor2" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor3" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor4" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor5" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor6" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor7" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor8" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor9" data-fg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor10" data-fg-color="" data-trix-method="hideDialog"></button>
        </div>
        <div class="trix-button-group">
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor1" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor2" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor3" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor4" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor5" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor6" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor7" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor8" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor9" data-bg-color="" data-trix-method="hideDialog"></button>
          <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor10" data-bg-color="" data-trix-method="hideDialog"></button>
        </div>
      </div>
    </div>
  `;
}

// Adds color pickers for text
addEventListener("trix-initialize", function(event) {
  event.target.toolbarElement.querySelector(".trix-button-group--text-tools").insertAdjacentHTML("beforeend", trixColorButton());
  event.target.toolbarElement.querySelector("[data-trix-dialogs]").insertAdjacentHTML("beforeend", trixColorDialog());
})
