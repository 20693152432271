import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["controlBtn", "checkBox", "selectAllBtn", "deselectAllBtn"]
  static values = { initialToggle: { type: Boolean, default: false } }

  initialize() {
    if (this.initialToggleValue == true) {
      this.initialToggle()
    }
  }

  selectAll(e) {
    if (e.target.type !== "checkbox") {
      e.preventDefault()
    }

    this.checkBoxTargets.forEach(checkBox => {
      checkBox.checked = true
    })
  }

  deselectAll(e) {
    if (e.target.type !== "checkbox") {
      e.preventDefault()
    }

    this.checkBoxTargets.forEach(checkBox => {
      checkBox.checked = false
    })
  }

  toggleBtn() {
    if (event.target == this.selectAllBtnTarget) {
      this.showDeselectAll()
    } else {
      this.showSelectAll()
    }
  }

  initialToggle() {
    let selectAll = false

    this.checkBoxTargets.forEach(checkBox => {
      if (checkBox.checked === false) {
        selectAll = true
      }
    })

    if (selectAll === true) {
      this.showSelectAll()
    } else {
      this.showDeselectAll()
    }
  }

  showSelectAll() {
    this.selectAllBtnTarget.classList.remove("hidden")
    this.deselectAllBtnTarget.classList.add("hidden")
  }

  showDeselectAll() {
    this.selectAllBtnTarget.classList.add("hidden")
    this.deselectAllBtnTarget.classList.remove("hidden")
  }
}
