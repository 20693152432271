import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output", "input", "removeBtn" ]

  showPreview() {
    const input = this.inputTarget
    const output = this.outputTarget

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
      this.showRemoveBtn();
    }
  }

  hidePreview() {
    this.inputTarget.value = "";
    this.hideRemoveBtn();
  }

  showRemoveBtn() {
    if (this.hasRemoveBtnTarget) {
      this.removeBtnTarget.classList.remove("hidden");
    }
  }

  hideRemoveBtn() {
    if (this.hasRemoveBtnTarget) {
      this.removeBtnTarget.classList.add("hidden");
    }
  }
}
