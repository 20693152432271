import { Controller } from "@hotwired/stimulus"
import { hideElement, showElement } from "../helpers/dom_helper"

export default class extends Controller {
  static targets = ["showButton", "hideButton", "checkbox", "panel"]
  static values = { performOnConnect: Boolean }

  connect() {
    if (this.performOnConnectValue) {
      if (this.hasCheckboxTarget) {
        this.toggleFromCheckbox({ animate: false })
      }
    }
  }

  toggle() {
    event.preventDefault()

    if (this.panelTarget.style.maxHeight) {
      showElement(this.showButtonTarget)
      hideElement(this.hideButtonTarget)
      this.panelTarget.classList.remove("accordion-panel--active")
      this.panelTarget.style.maxHeight = null
    } else {
      hideElement(this.showButtonTarget)
      showElement(this.hideButtonTarget)
      this.panelTarget.classList.add("accordion-panel--active")
      this.panelTarget.style.maxHeight = this.panelTarget.scrollHeight + "px"
    }
  }

  toggleFromCheckbox({ animate = true }) {
    if (!this.checkboxTarget.checked) {
      this.panelTarget.classList.remove("accordion-panel--active")
      this.panelTarget.style.maxHeight = null
    } else {
      if (!animate) this.disableAnimations()
      this.panelTarget.classList.add("accordion-panel--active")
      this.panelTarget.style.maxHeight = this.panelTarget.scrollHeight + "px"
    }
  }

  disableAnimations() {
    this.panelTarget.classList.add("accordion-panel--no-transition")

    setTimeout(() => {
      this.panelTarget.classList.remove("accordion-panel--no-transition")
    }, "2000");
  }
}
