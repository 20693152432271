import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.previewModeEnabled) {
      this.disableInteraction();
    }
  }

  disableInteraction() {
    this.element.style.pointerEvents = "none";
  }

  get previewModeEnabled() {
    return this.data.get("previewModeEnabled") == "true";
  }
}
