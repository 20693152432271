import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addButton", "removeButton", "destination", "placeholder"]

  hiddenClass = 'hidden';

  render(event) {
    let placeholder = document.querySelector(`[data-inline-edit-placeholder='${event.currentTarget.dataset.destination}']`)
    let container = document.querySelector(`[data-inline-edit-destination='${event.currentTarget.dataset.destination}']`)

    placeholder.classList.add(this.hiddenClass)
    container.innerHTML = ""
    container.insertAdjacentHTML("beforeend", event.currentTarget.dataset.partial)
  }

  remove(event) {
    let placeholder = document.querySelector(`[data-inline-edit-placeholder='${event.currentTarget.dataset.destination}']`)
    let container = document.querySelector(`[data-inline-edit-destination='${event.currentTarget.dataset.destination}']`)

    placeholder.classList.remove(this.hiddenClass)
    container.innerHTML = ""
  }
}
