import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["input"]

	tabToNextInput(e){
		const currentInput = e.target

		if (currentInput.value.length != currentInput.dataset.numCharsForAutoTab) return

		const currentIndex = this.inputTargets.indexOf(currentInput)
		const nextInput = this.inputTargets[currentIndex + 1]

		nextInput.focus()
		nextInput.select()
	}
}
