import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "styleToggle"]
  static values = { targetContainer: String, url: String }

  toggleStyle(e) {
    if (this.hasStyleToggleTarget) {
      this.styleToggleTarget.checked = true
    }
  }

  addLoadingSpinner(e) {
    if (this.hasTargetContainerValue) {
      const container = document.getElementById(this.targetContainerValue)

      container.insertAdjacentHTML("beforeend", `<div class="turbo-loading-ring"><div></div><div></div><div></div><div></div></div>`)
      container.classList.add("turbo-loading")
    }
  }

  updateURL() {
    if (this.hasUrlValue) {
      const url = new URL(this.urlValue)

      history.pushState({}, null, url.toString())
      Turbo.navigator.history.replace(url.toString())
    }
  }

  manualStream(e) {
    if (e && e.target.tagName !== "INPUT") return

    let token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(this.urlValue, {
      headers: {
        "X-CSRF-Token": token,
        "Accept": "text/vnd.turbo-stream.html",
      },
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  manualStreamFromSelect(e) {
    const paramName = this.element.dataset.paramName
    const val = e.target.value
    const delimiter = this.urlValue.includes("?") ? "&" : "?"

    this.urlValue += `${delimiter}${paramName}=${val}`

    this.manualStream()
  }
}
