import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, refreshInterval: Number, stopRefreshing: Boolean, refresh: Boolean }

  connect() {
    if (!this.urlValue) return

    this.refreshValue ||= false

    this.load()

    if (!this.stopRefreshing()) this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
    })

    this.checkForStopRefreshing()
  }

  startRefreshing() {
    if (this.refreshValue) {
        this.refreshTimer = setInterval(() => {
        this.load()
      }, this.refreshIntervalValue)
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  checkForStopRefreshing() {
    let stopRefreshingElement = this.element.querySelector("[data-stop-refreshing='true']")
    if (stopRefreshingElement) {
      this.stopRefreshing()
      return true
    } else {
      return false
    }
  }
}
