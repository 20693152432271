import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bar", "numItems", "numItemsCompleted", "checkbox"]
  static values = { numItems: Number, numItemsCompleted: Number }

  progressFromCheckbox(e) {
    e.target.checked ? this.numItemsCompletedValue += 1 : this.numItemsCompletedValue -= 1

    this.updateProgress()
  }

  updateProgress() {
    if (this.hasBarTarget) this.barTarget.style.width = `${this.percentComplete()}%`
    if (this.hasNumItemsCompletedTarget) this.numItemsCompletedTarget.innerText = this.numItemsCompletedValue
    if (this.hasNumItemsTarget) this.numItemsTarget.innerText = this.numItemsValue
  }

  percentComplete() {
    if (this.numItemsValue === 0) {
      return 100
    } else {
      return (this.numItemsCompletedValue / this.numItemsValue) * 100
    }
  }

  checkboxTargetConnected(element) {
    this.numItemsValue += 1
    if (element.checked) this.numItemsCompletedValue += 1
    this.updateProgress()
  }

  checkboxTargetDisconnected(element) {
    this.numItemsValue -= 1
    if (element.checked) this.numItemsCompletedValue -= 1
    this.updateProgress()
  }
}

