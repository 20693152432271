import { Controller } from "@hotwired/stimulus"
import Money from 'src/dinero-custom'

export default class extends Controller {
  static targets = ['amountField', 'refundAmountField', 'refundTotal', 'submitButton']

  connect() {
    this.toggleSubmitButton()
  }

  amountFieldInput(e) {
    let amountField = e.currentTarget
    let refund = new Money({ amount: amountField.value })
    let balance = new Money({ amount: amountField.getAttribute('data-balance') })
    let total = this.updateRefundAmount()

    if ( refund.amount > balance.amount ) {
      this.addAmountFieldError(amountField, 'Cannot exceed payment balance.')
    } else {
      this.removeAmountFieldError(amountField)
    }

    this.toggleSubmitButton(total)
  }

  updateRefundAmount() {
    let refundTotal = this.refundSum()

    this.refundAmountFieldTarget.value = refundTotal.toInput()
    this.refundTotalTarget.innerHTML = refundTotal.toText()

    return refundTotal
  }

  refundSum() {
    let sum = new Money({ amount: 0 })

    this.amountFieldTargets.forEach(amountField => {
      sum = sum.add(amountField.value)
    })

    return sum
  }

  addAmountFieldError(amountField, error) {
    let fieldContainer = amountField.closest("td")
    let messageContainer = fieldContainer.querySelector("[data-messages='error']")
    let errorMessage = document.createElement('span')

    errorMessage.textContent = error
    errorMessage.classList.add('field-error-message')

    fieldContainer.querySelector('.input-wrapper').classList.add('field_with_errors')
    messageContainer.innerHTML = ""
    messageContainer.appendChild(errorMessage)
  }

  removeAmountFieldError(amountField) {
    let fieldContainer = amountField.closest("td")

    fieldContainer.querySelector('.input-wrapper').classList.remove('field_with_errors')
    fieldContainer.querySelector("[data-messages='error']").innerHTML = ""
  }

  noFormErrors() {
    return document.querySelectorAll('.input-wrapper.field_with_errors').length == 0
  }

  toggleSubmitButton(refundTotal = null) {
    if (refundTotal == null) {
      refundTotal = this.refundSum()
    }

    if ( refundTotal.amount > 0 && this.noFormErrors() ) {
      this.submitButtonTarget.classList.remove("button--disabled")
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.classList.add("button--disabled")
      this.submitButtonTarget.disabled = true
    }
  }
}
