import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['shareButton']

  share() {
    if (!this.disabled) {
      const shareData = this.shareButtonTarget.dataset
      const site = shareData.site || ''
      const cacheTimestamp = shareData.cacheTimestamp || ''
      const subject = shareData.subject || ''
      let url = shareData.url || location.href;
      let defaultMessage = shareData.defaultMessage || ''

      if (site != 'email' && cacheTimestamp.length > 0) {
        const queryStringDelimiter = (url.indexOf('?') == -1 ? '?' : '&')
        url = encodeURIComponent(`${url}${queryStringDelimiter}shareid=${cacheTimestamp}`)
      }

      if (site == 'email') {
        location.href = this.getEmailLink(subject, defaultMessage, url)
      } else if (site == 'facebook') {
        this.popup(`http://www.facebook.com/sharer/sharer.php?u=${url}&quote=${defaultMessage}`, 555, 400)
      } else if (site == 'twitter') {
        defaultMessage = escape(defaultMessage + '\n')
        const hashtags = encodeURIComponent(shareData.twitterHashtags || '')
        this.popup(`http://twitter.com/intent/tweet?text=${defaultMessage}${url}&hashtags=${hashtags}`, 650, 300)
      }
    }
  }

  popup(url, width = 640, height = 480) {
    const left = (screen.width / 2) - (width / 2)
    const top = (screen.height * 0.3) - (height / 2)
    const options = `width=${width},height=${height},left=${left},top=${top},menubar=no,status=no,location=no`
    window.open(url, 'popup', options)
  }

  // Conditionals required for outlook support
  getEmailLink(subject, defaultMessage, url) {
    if (subject.length && defaultMessage.length) {
      return `mailto:?subject=${subject}&body=%0d%0a${defaultMessage}%0d%0a${url}`
    } else if (subject.length && url.length) {
      return `mailto:?subject=${subject}&body=%0d%0a${url}`
    } else if (subject.length) {
      return `mailto:?subject=${subject}`
    } else if (defaultMessage.length){
      return `mailto:?body=%0d%0a${defaultMessage}%0d%0a${url}`
    } else if (url.length) {
      return `mailto:?body=%0d%0a${url}`
    } else {
      return 'mailto:'
    }
  }

  get disabled() {
    return this.data.get("disabled") == "true";
  }
}
