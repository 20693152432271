import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item", "button", "collapseButton" ]

  connect() {
    this.incrementCurrentIndex()
    this.checkIfComplete()
  }

  toggleItems() {
    this.itemTargets.forEach((el, i) => {
      if (i < this.currentIndex) {
        el.classList.remove("hidden")
      } else {
        el.classList.add("hidden")
      }
    })
  }

  getResults(e) {
    e.preventDefault()

    if (this.showAll) {
      this.currentIndex = this.itemTargets.length
    } else {
      this.incrementCurrentIndex()
    }

    this.checkIfComplete()
  }

  incrementCurrentIndex() {
    this.currentIndex += this.initialIndex
  }

  checkIfComplete() {
    if (this.currentIndex >= this.itemTargets.length) {
      if (this.hasButtonTarget) {
        this.buttonTarget.classList.add("hidden")
      }

      if (this.hasCollapseButtonTarget) {
        this.collapseButtonTarget.classList.remove("hidden")
      }
    }
  }

  collapse(e) {
    e.preventDefault()

    this.itemTargets.forEach((el, i) => {
      el.classList.add("hidden")
    })

    this.collapseButtonTarget.classList.add("hidden")
    this.buttonTarget.classList.remove("hidden")
  }

  get initialIndex() {
    return parseInt(this.data.get("initialIndex") || 0)
  }

  get currentIndex() {
    return parseInt(this.data.get("currentIndex") || 0)
  }

  get showAll() {
    return this.data.get("showAll") == "true"
  }

  set currentIndex(value) {
    this.data.set("currentIndex", value)
    this.toggleItems()
  }
}
