import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "stickySaveContainer", "saveBtn"]
  static values = { changed: Boolean, override: Boolean, omitFields: Array }

  connect() {
    const fieldsToAlwaysOmit = ["authenticity_token"]
    this.omitFieldsValue ||= []
    this.omitFieldsValue = [...this.omitFieldsValue, ...fieldsToAlwaysOmit]

    this.initialFormObject = this.formObject()

    if (this.hasStickySaveContainerTarget) this.toggleStickySaveContainer()
  }

  detectChange() {
    this.changedValue = false
    const newFormObject = this.formObject()

    for (const [key, value] of Object.entries(newFormObject)) {
      if (this.omitFieldsValue.includes(key)) continue

      if (this.initialFormObject[key] !== value) {
        this.changedValue = true
        break
      }
    }

    if (this.hasStickySaveContainerTarget) {
      this.toggleStickySaveContainer()
    } else if (this.hasSaveBtnTarget) {
      this.toggleSaveBtn()
    }
  }

  toggleStickySaveContainer() {
    if (this.changedValue || this.overrideValue) {
      this.stickySaveContainerTarget.classList.remove("hidden")
    } else {
      this.stickySaveContainerTarget.classList.add("hidden")
    }
  }

  toggleSaveBtn() {
    if (this.changedValue || this.overrideValue) {
      this.saveBtnTarget.classList.remove("button--disabled")
    } else {
      this.saveBtnTarget.classList.add("button--disabled")
    }
  }

  formObject() {
    let object = {}
    const formData = new FormData(this.formTarget)

    for (let [key, value] of formData) {
      if (this.omitFieldsValue.includes(key)) continue

      object[key] = value
    }

    return object
  }
}
